var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { capitalize } from 'lodash';
//
import { providerNameMapper } from '../../../utils/common';
import { printDate, printEffectiveDate } from '../../../utils/date';
import api from '../../../api';
var LimitPolicyPreview = function (_a) {
    var quoteId = _a.quoteId, policy = _a.policy;
    var documents = useQuery({
        queryKey: ['quote', 'limit', 'policy-documents'],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.quote.listLimitPolicyDocuments({
                            quoteId: quoteId,
                            limitPolicyId: policy.id
                        })];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data.data];
                }
            });
        }); }
    }).data;
    var total = useMemo(function () {
        var _a, _b;
        var prices = __spreadArray([
            {
                label: 'Premium',
                amount: (((_a = policy.premium) === null || _a === void 0 ? void 0 : _a.amount) || 0) / 100
            }
        ], (((_b = policy === null || policy === void 0 ? void 0 : policy.fees) === null || _b === void 0 ? void 0 : _b.map(function (fee) { return ({
            label: capitalize(fee.kind.replaceAll('_', ' ')),
            amount: fee.fee.amount / 100
        }); })) || []), true);
        var total = prices.reduce(function (prev, curr) {
            return prev + curr.amount;
        }, 0);
        return { prices: prices, total: total };
    }, [policy]).total;
    var carrierName = providerNameMapper[policy.market] || capitalize(policy.market);
    var hasDocuments = (documents === null || documents === void 0 ? void 0 : documents.length) > 0;
    return (React.createElement("div", { className: "flex gap-6 items-start text-left" },
        React.createElement("ul", { className: "max-w-sm w-full border rounded-lg border-[#E9EDF7] flex flex-col gap-2 sticky top-24" },
            React.createElement("li", { className: "border-b border-[#E9EDF7] p-4" },
                React.createElement("h3", { className: "text-lg font-medium" }, "Carrier"),
                React.createElement("p", null, carrierName)),
            React.createElement("li", { className: "border-b border-[#E9EDF7] p-4" },
                React.createElement("h3", { className: "text-lg font-medium" }, "Lines"),
                React.createElement("p", null, policy.lines.map(function (_a) {
                    var line = _a.line;
                    return capitalize(line);
                }).join(', '))),
            React.createElement("li", { className: "border-b border-[#E9EDF7] p-4" },
                React.createElement("h3", { className: "text-lg font-medium" }, "Total Price"),
                React.createElement("p", null,
                    "$",
                    total.toLocaleString())),
            React.createElement("li", { className: "border-b border-[#E9EDF7] p-4" },
                React.createElement("h3", { className: "text-lg font-medium" }, "Effective Date"),
                React.createElement("p", null, printEffectiveDate(policy.effective_date))),
            React.createElement("li", { className: "border-b border-[#E9EDF7] p-4" },
                React.createElement("h3", { className: "text-lg font-medium" }, "Quote Expiration Date"),
                React.createElement("p", null, printDate(policy.quote_expiration_date))),
            React.createElement("li", { className: "p-4" },
                React.createElement("h3", { className: "text-lg font-medium" }, "Aggregate Limit"),
                React.createElement("p", null,
                    "$",
                    (policy.limit.amount / 100).toLocaleString()))),
        React.createElement("div", { className: "flex-1" },
            React.createElement("div", { className: "p-6 border border-[#E9EDF7] rounded-lg mb-6" },
                React.createElement("h2", { className: "text-2xl font-medium mb-6" }, "Contingencies"),
                React.createElement("ul", { className: "ml-3 bg-slate-100 p-3 rounded-lg" }, policy.contingencies.map(function (contingency, i) {
                    var isLast = i === policy.contingencies.length - 1;
                    return (React.createElement("li", { key: contingency.id, className: isLast ? '' : 'mb-4 border-b border-[#E9EDF7] py-3' },
                        React.createElement("p", { className: "text-sm mb-3" }, contingency.text),
                        React.createElement("div", { className: "flex items-center gap-3" },
                            contingency.pre_bind && (React.createElement("span", { className: "p-2 bg-[#E9EDF7] rounded-sm text-sm" }, "Pre Bind")),
                            React.createElement("span", { className: "text-sm" }, printDate(contingency.created_at)))));
                }))),
            React.createElement("div", { className: "p-6 border border-[#E9EDF7] rounded-lg mb-6" },
                React.createElement("h2", { className: "text-2xl font-medium mb-4" }, "Documents"),
                hasDocuments ? (React.createElement("ul", null, documents.map(function (document) { return (React.createElement("li", { className: "mb-2" },
                    React.createElement("a", { href: document.download_url, target: "_blank", className: "text-blue-500 text-sm underline hover:text-blue-600" }, document.name))); }))) : (React.createElement("p", null, "Loading..."))),
            React.createElement("div", { className: "p-6 border border-[#E9EDF7] rounded-lg mb-6" },
                React.createElement("h2", { className: "text-2xl font-medium mb-4" }, "Coverages"),
                React.createElement("table", { className: "border-collapse w-full" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "p-3" }, "What's covered?"),
                            React.createElement("th", { className: "p-3" }, "Sublimit"),
                            React.createElement("th", { className: "p-3" }, "Subretention"))),
                    React.createElement("tbody", null, policy.lines.map(function (line) { return (React.createElement(React.Fragment, null, line.coverages.map(function (coverage) {
                        var sublimit = coverage.sublimit
                            ? (coverage.sublimit.amount / 100).toLocaleString()
                            : 'See document';
                        var subretention = coverage.subretention
                            ? (coverage.subretention.amount / 100).toLocaleString()
                            : 'See document';
                        return (React.createElement("tr", null,
                            React.createElement("td", { className: "p-3" }, coverage.name),
                            React.createElement("td", { className: "p-3" }, sublimit),
                            React.createElement("td", { className: "p-3" }, subretention)));
                    }))); })))),
            React.createElement("div", { className: "p-6 border border-[#E9EDF7] rounded-lg" },
                React.createElement("h2", { className: "text-2xl font-medium mb-4" }, "Endorsements"),
                React.createElement("table", { className: "border-collapse w-full" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "p-3" }, "Endorsement Title"),
                            React.createElement("th", { className: "p-3" }, "Form Number in Specimen"))),
                    React.createElement("tbody", null, policy.endorsements.map(function (endorsement) { return (React.createElement("tr", null,
                        React.createElement("td", { className: "p-3" }, endorsement.title),
                        React.createElement("td", { className: "p-3" }, endorsement.form_code))); })))))));
};
export default LimitPolicyPreview;
