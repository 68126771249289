import React, { useEffect, useState } from 'react';
import PolicyStepsHeader from '../../../common/PolicyStepsHeader';
import SelectableAccordion from '../../../common/SelectableAccordion';
import useCustomerStore from '../../../../stores/customerStore';
import useLatestQuotesWithPolicies from '../../../../hooks/useLatestQuotesWithPolicies';
import useConfigStore from '../../../../stores/configStore';
import useWidgetStore from '../../../../stores/widgetStore';
import StartRequest from './StartRequest';
import useProductStore from '../../../../stores/productStore';
import useSaasPlatformStore from '../../../../stores/saasPlatformStore';
import shade from '../../../../utils/shade';
var SelectApplicationDetails = function (_a) {
    var createQuoteMutation = _a.createQuoteMutation;
    var customerQuotes = useLatestQuotesWithPolicies();
    var _b = useConfigStore(), widgetType = _b.keys.widgetType, style = _b.style;
    var primaryColor = style.primaryColor, theme = style.theme;
    var Products = useSaasPlatformStore().Products;
    var _c = useWidgetStore(), applicationDataId = _c.applicationDataId, modalOpen = _c.modalOpen, setFormActive = _c.setFormActive, setModalOpen = _c.setModalOpen, handleAnswers = _c.setAnswers, setApplicationDataId = _c.setApplicationDataId, setCurrentTab = _c.setCurrentTab;
    var handleProduct = useProductStore().setProduct;
    var applicationFormData = useCustomerStore().applicationFormData;
    var _d = useState(null), selectionError = _d[0], setSelectionError = _d[1];
    var _e = useState({ externalId: null, applicationData: null }), selectedData = _e[0], setSelectedData = _e[1];
    var _f = useState([]), generatedQuotesExternalIds = _f[0], setGeneratedQuotesExternalIds = _f[1];
    useEffect(function () {
        if (customerQuotes && customerQuotes.length > 0) {
            var externalRefrenceOfQuotes = customerQuotes.map(function (quote) { return quote === null || quote === void 0 ? void 0 : quote.externalId; });
            setGeneratedQuotesExternalIds(externalRefrenceOfQuotes);
        }
    }, [customerQuotes]);
    useEffect(function () {
        var _a;
        if (applicationFormData && modalOpen) {
            var policyKeys = Object.keys(applicationFormData);
            var key = policyKeys[0];
            var applicationData_1 = applicationFormData[key];
            var findProd = (_a = Products === null || Products === void 0 ? void 0 : Products.find) === null || _a === void 0 ? void 0 : _a.call(Products, function (p) { return p.id == applicationData_1.productId; });
            if (policyKeys.length === 1 && findProd) {
                if (widgetType == 'broker') {
                    createQuoteMutation({
                        requiredParams: applicationData_1 === null || applicationData_1 === void 0 ? void 0 : applicationData_1.applicationDetails,
                        optionalParams: {}
                    }, applicationData_1 === null || applicationData_1 === void 0 ? void 0 : applicationData_1.productId, key);
                }
                else {
                    if (key && applicationData_1 && Products && (Products === null || Products === void 0 ? void 0 : Products.length) > 0) {
                        setApplicationDataId(key);
                        handleAnswers(applicationData_1.applicationDetails);
                        handleProduct(findProd);
                    }
                    else {
                        setApplicationDataId(key);
                        if (Products && (Products === null || Products === void 0 ? void 0 : Products.length) > 0) {
                            var findProd_1 = Products.find(function (p) { return p.id == applicationData_1.productId; });
                            handleProduct(findProd_1);
                        }
                    }
                }
                setFormActive(true);
            }
        }
    }, [applicationFormData, modalOpen, widgetType, Products]);
    return (React.createElement("div", { className: modalOpen ? 'w-full overflow-x-hidden' : '' }, modalOpen ? (React.createElement("div", { className: "w-full h-[92vh] absolute top-0 -ml-4 flex flex-col items-center justify-start overflow-x-hidden sm:w-full sm:h-[95%]" },
        React.createElement("div", { className: "w-full shadow-sm sticky top-0 bg-white" },
            React.createElement(PolicyStepsHeader, { nextButtonText: widgetType == 'broker' ? 'Agree & Continue' : 'Continue', backButtonText: "Close", pageName: "Select Data", isNextDisabled: !(selectedData.externalId &&
                    selectedData.applicationData.productId), handleNext: function () {
                    var _a, _b, _c, _d, _e;
                    if (widgetType == 'broker') {
                        var isEMR = (_b = (_a = selectedData === null || selectedData === void 0 ? void 0 : selectedData.applicationData) === null || _a === void 0 ? void 0 : _a.applicationDetails) === null || _b === void 0 ? void 0 : _b.HorseDetails;
                        if (!(isEMR &&
                            (isEMR === null || isEMR === void 0 ? void 0 : isEMR.length) == ((_c = isEMR === null || isEMR === void 0 ? void 0 : isEMR.data) === null || _c === void 0 ? void 0 : _c.length) &&
                            (isEMR === null || isEMR === void 0 ? void 0 : isEMR.length) <= 5)) {
                            setSelectionError({
                                key: selectedData.externalId,
                                message: 'One Policy Can Only Insure 5 or less Horses'
                            });
                            return;
                        }
                        createQuoteMutation({
                            requiredParams: (_d = selectedData === null || selectedData === void 0 ? void 0 : selectedData.applicationData) === null || _d === void 0 ? void 0 : _d.applicationDetails,
                            optionalParams: {}
                        }, (_e = selectedData === null || selectedData === void 0 ? void 0 : selectedData.applicationData) === null || _e === void 0 ? void 0 : _e.productId, selectedData === null || selectedData === void 0 ? void 0 : selectedData.externalId);
                    }
                    else {
                        if ((selectedData === null || selectedData === void 0 ? void 0 : selectedData.externalId) &&
                            (selectedData === null || selectedData === void 0 ? void 0 : selectedData.applicationData)) {
                            setApplicationDataId(selectedData === null || selectedData === void 0 ? void 0 : selectedData.externalId);
                            // setProduct(selectedData.applicationData.productId);
                            // setAnswers(selectedData.applicationData.applicationDetails);
                            handleAnswers(selectedData.applicationData.applicationDetails);
                            if (Products && (Products === null || Products === void 0 ? void 0 : Products.length) > 0) {
                                var findProd = Products.find(function (p) { return p.id == selectedData.applicationData.productId; });
                                handleProduct(findProd);
                            }
                        }
                        else {
                            // setAnswers(null);
                            // setProduct(selectedData.applicationData.productId);
                            setApplicationDataId(selectedData === null || selectedData === void 0 ? void 0 : selectedData.externalId);
                            if (Products && (Products === null || Products === void 0 ? void 0 : Products.length) > 0) {
                                var findProd = Products.find(function (p) { return p.id == selectedData.applicationData.productId; });
                                handleProduct(findProd);
                            }
                        }
                        setFormActive(true);
                    }
                } })),
        React.createElement("div", { className: "w-full px-6 flex flex-col gap-y-4 mt-6" }, Object.keys(applicationFormData).map(function (key) {
            var _a, _b;
            return (!generatedQuotesExternalIds.includes(key) && (React.createElement(SelectableAccordion, { title: key, data: applicationFormData && applicationFormData[key], handleSelectData: function () {
                    if (applicationFormData)
                        setSelectedData({
                            externalId: key,
                            applicationData: applicationFormData[key]
                        });
                }, isDataAvailable: applicationFormData &&
                    ((_a = applicationFormData[key]) === null || _a === void 0 ? void 0 : _a.applicationDetails), productId: applicationFormData && ((_b = applicationFormData[key]) === null || _b === void 0 ? void 0 : _b.productId), isSelected: (selectedData === null || selectedData === void 0 ? void 0 : selectedData.externalId) == key, selectionError: selectionError && (selectionError === null || selectionError === void 0 ? void 0 : selectionError.key) == key
                    ? selectionError === null || selectionError === void 0 ? void 0 : selectionError.message
                    : null })));
        })))) : (React.createElement(StartRequest, { theme: theme, style: {
            backgroundColor: theme == 'night'
                ? shade(primaryColor, -0.7)
                : shade(primaryColor, 0.9)
        }, isButtonDisabled: false, handleClick: function () {
            // let questionFilter =
            //   findQuestionsWithConditions(currQuestionGroup);
            // if (latestQuote || Object.keys(questions as any)?.length == 1) {
            //   setQuestionLast(true);
            // }
            setModalOpen(true);
            // setAnswers(applicationFormData);
            // setNavigateHistory([0]);
            // setQuestionsStart(false);
        }, 
        // isButtonDisabled={
        //   !questions || Object.entries(questions).length == 0
        // }
        buttonLabel: 'Start Request' }))));
};
export default SelectApplicationDetails;
