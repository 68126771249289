var requiredDependency = {
    physical_address: {
        required_if: {
            physical_address_same_as_mailing: false
        }
    },
    pci_compliance_with_na: {
        required_if: {
            accepts_credit_cards: true
        }
    },
    pci_compliant_payments: {
        required_if: {
            accepts_credit_cards: true,
            pci_compliance_with_na: 'No'
        }
    },
    has_offline_or_cloud_backups: {
        required_if: {
            has_backups: true
        }
    },
    critical_info_backup_cadence: {
        required_if: {
            has_backups: true
        }
    },
    critical_system_rto: {
        required_if: {
            has_backups: true
        }
    },
    email_security_software: {
        required_if: {
            has_email_filtering: true
        }
    },
    mfa_for_remote_access: {
        required_if: {
            allows_remote_access: true
        }
    },
    mfa_provider: {
        required_if: {
            allows_remote_access: true
        }
    },
    backup_protected_by_mfa: {
        required_if: {
            has_backups: true
        }
    },
    anti_virus_provider: {
        required_if: {
            endpoint_protection_for_all_devices: true
        }
    },
    edr_provider: {
        required_if: {
            endpoint_protection_for_all_devices: true
        }
    },
    claims_legal_or_regulatory_actions_most_recent: {
        required_if: {
            claims_legal_or_regulatory_actions_past_5_years: true
        }
    },
    claims_legal_or_regulatory_actions_past_5_years_amount: {
        required_if: {
            claims_legal_or_regulatory_actions_past_5_years: true
        }
    }
};
export default requiredDependency;
