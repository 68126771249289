import React, { useState } from 'react';
import AccorddionCloseIcon from '../icons/AccordionCloseIcon';
import AccordionOpenIcon from '../icons/AccordionOpenIcon';
import * as changeCase from 'change-case';
var renderData = function (data, parentKey, previewDetails) {
    if (parentKey === void 0) { parentKey = ''; }
    if (previewDetails === void 0) { previewDetails = {}; }
    if (typeof data !== 'object' || data === null) {
        return (React.createElement("div", { className: "w-full grid grid-cols-5 border rounded-xl divide-x-2", key: parentKey },
            React.createElement("div", { className: "col-span-3 self-center py-3.5" }, changeCase.sentenceCase(parentKey)),
            React.createElement("div", { className: "col-span-2 font-bold self-center py-3.5 px-2" }, String(data))));
    }
    return Object.keys(data).map(function (key, parIndex) {
        var _a;
        var isHidden = (_a = previewDetails === null || previewDetails === void 0 ? void 0 : previewDetails.hide) === null || _a === void 0 ? void 0 : _a.includes(key);
        if (isHidden) {
            return null;
        }
        var value = data[key];
        return (React.createElement("div", { key: parentKey + key, className: "flex flex-col mb-4" },
            Array.isArray(value) ? (React.createElement("span", { className: "capitalize text-xl font-semibold my-4 text-left" }, changeCase.sentenceCase(key))) : (parIndex == 0 &&
                parentKey && (React.createElement("span", { className: "capitalize text-xl font-semibold my-4 text-left" }, changeCase.sentenceCase(parentKey)))),
            React.createElement("div", { className: "w-full mt-3 gap-x-2 gap-y-2" }, Array.isArray(value)
                ? value.map(function (item, index) { return (React.createElement("div", { key: index, className: "w-full border-2 rounded-lg my-6 flex flex-col items-start py-4 divide-y-2" },
                    React.createElement("span", { className: "capitalize text-xl font-semibold mb-4 px-6" }, "".concat(changeCase.sentenceCase(parentKey ? parentKey : key), " ").concat(index + 1)),
                    React.createElement("div", { className: "w-full grid grid-cols-4 gap-6 items-end p-6" }, renderData(item, key)))); })
                : renderData(value, key))));
    });
};
var SelectableAccordion = function (_a) {
    var title = _a.title, data = _a.data, handleSelectData = _a.handleSelectData, isDataAvailable = _a.isDataAvailable, productId = _a.productId, isSelected = _a.isSelected, selectionError = _a.selectionError;
    var _b = useState(false), openAccordion = _b[0], setOpenAccordion = _b[1];
    return (React.createElement("div", null,
        React.createElement("button", { className: "w-full flex items-center justify-between bg-[#F5F7FA] px-4 py-3 rounded-xl ".concat(selectionError ? 'border-2 border-red-500 text-red-600' : ''), onClick: function () { return setOpenAccordion(!openAccordion); } },
            React.createElement("div", { className: "flex items-center gap-x-4" },
                isDataAvailable &&
                    (openAccordion ? React.createElement(AccorddionCloseIcon, null) : React.createElement(AccordionOpenIcon, null)),
                React.createElement("span", { className: "text-lg font-semibold" }, isDataAvailable
                    ? "Data ".concat(changeCase.sentenceCase(title))
                    : "Product ".concat(productId))),
            React.createElement("input", { checked: isSelected, type: "checkbox", className: "w-5 h-5 accent-primary text-white rounded-3xl border-2 border-primary/30", onClick: function (e) {
                    e.stopPropagation();
                    handleSelectData();
                } })),
        selectionError && (React.createElement("div", { className: 'text-left my-2 ml-1' },
            React.createElement("span", { className: "text-red-500 text-xs font-medium" }, selectionError))),
        openAccordion && isDataAvailable && (React.createElement("div", { className: "text-center" }, renderData(data === null || data === void 0 ? void 0 : data.applicationDetails, undefined, data === null || data === void 0 ? void 0 : data.previewConfig)))));
};
export default SelectableAccordion;
