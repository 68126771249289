var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { capitalize } from 'lodash';
// Components
import LimitPolicyPreview from './LimitPolicyPreview';
import LimitPending from './LimitPending';
import QuoteLoading from './QuoteLoading';
import PolicyStepsHeader from '../../common/PolicyStepsHeader';
import Checkbox from '../../common/Checkbox';
//
import 'swiper/css';
import 'swiper/css/navigation';
import '../../../assets/styles/carousel.css';
import { providerNameMapper } from '../../../utils/common';
import api from '../../../api';
import CloseIcon from '../../icons/CloseIcon';
var animationClasses = 'bg-[#F5F7FA] dark:bg-[#e7e9eb] rounded animate-pulse';
var Slide = function (_a) {
    var isRecommended = _a.isRecommended, provider = _a.provider, total = _a.total, prices = _a.prices, onPurchase = _a.onPurchase, onViewDetails = _a.onViewDetails, status = _a.status;
    var carrierName = providerNameMapper[provider] || capitalize(provider);
    var isReady = status === 'quote';
    return (React.createElement("div", { className: "policy-slide w-[266px] mx-auto bg-[#F5F7FA] rounded-2xl ".concat(isReady ? '' : 'grayscale') },
        React.createElement("div", { className: "".concat(isRecommended ? 'flex' : 'hidden', " h-6 items-center justify-center bg-primary") },
            React.createElement("span", { className: "text-white text-xs font-semibold" }, "Recommended")),
        React.createElement("div", { className: "p-6 pt-8 flex flex-col gap-6 h-full" },
            React.createElement("div", null,
                React.createElement("h4", { className: "text-xs text-[#3B4265]" }, "Provider"),
                React.createElement("h3", { className: "mb-4 text-lg text-[#252846] font-semibold" }, carrierName),
                React.createElement("p", { className: "text-[28px] text-[#252846] font-semibold" }, isReady ? (React.createElement(React.Fragment, null,
                    "$",
                    total.toLocaleString(),
                    React.createElement("span", { className: "text-[#707EAE] text-sm" }, "/ Month"))) : (React.createElement("span", { className: "text-base leading-[42px] bg-[#F5F7FA]" }, "In Progress")))),
            React.createElement("div", { className: "border-t border-[#E9EDF7] pt-4 flex-1" },
                React.createElement("ul", { className: "flex flex-col gap-2" }, isReady
                    ? prices.map(function (_a) {
                        var label = _a.label, amount = _a.amount;
                        return (React.createElement("li", { className: "flex items-center justify-between" },
                            React.createElement("span", { className: "text-[#3B4265] text-xs" }, label.toLowerCase() === 'our brokerage fee'
                                ? 'Brokerage fee'
                                : label),
                            React.createElement("span", { className: "text-[#252846] font-semibold" },
                                "$",
                                amount.toLocaleString())));
                    })
                    : Array.from({ length: 3 }).map(function () {
                        return React.createElement("li", { className: "h-4 ".concat(animationClasses) });
                    }))),
            React.createElement("div", null,
                React.createElement("button", { onClick: onViewDetails, disabled: !isReady, className: "border border-[#C8CEE3] h-10 rounded-lg text-sm font-bold w-full text-[#707EAE] bg-white mb-2 ".concat(isReady ? '' : 'cursor-not-allowed') }, "Details"),
                React.createElement("button", { onClick: onPurchase, disabled: !isReady, className: "limit-purchase-button h-10 rounded-lg text-sm font-bold w-full border border-primary text-primary transition-all ".concat(isReady ? '' : 'cursor-not-allowed') }, "Purchase Policy")))));
};
var LimitPolicySelection = function (_a) {
    var latestQuote = _a.latestQuote, previewPolicyId = _a.previewPolicyId, setPreviewPolicyId = _a.setPreviewPolicyId, onBindPolicy = _a.onBindPolicy, closeModal = _a.closeModal, isSubmitted = _a.isSubmitted;
    var formRef = useRef(null);
    var _b = useState(null), purchaseQuoteId = _b[0], setPurchaseQuoteId = _b[1];
    var _c = useState(false), showError = _c[0], setShowError = _c[1];
    var _d = useQuery({
        queryKey: ['quote', 'limit', 'application-policies'],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, api.quote.getLimitApplicationPolicies({
                            quoteId: latestQuote.id
                        })];
                    case 1:
                        data = _c.sent();
                        return [2 /*return*/, (((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.filter(function (quote) {
                                var keeping = [
                                    'not_sent',
                                    'waiting_on_market',
                                    'clearance_review',
                                    'quote',
                                    'indication',
                                    'expired_quote',
                                ].includes(quote.status);
                                return keeping;
                            })) || [])];
                }
            });
        }); },
        refetchInterval: 60000,
        enabled: isSubmitted
    }), policies = _d.data, isLoading = _d.isLoading;
    var closeConfirmationModal = function () {
        setShowError(false);
        setPurchaseQuoteId(null);
    };
    var hasPolicies = policies && policies.length > 0;
    var viewDetailsHandler = function (policyId) {
        setPreviewPolicyId(policyId);
    };
    var backHandler = function () {
        setPreviewPolicyId(null);
    };
    var PreviewComponent = useMemo(function () {
        if (previewPolicyId) {
            var previewPolicy = policies.find(function (p) { return p.id === previewPolicyId; });
            return (React.createElement(LimitPolicyPreview, { quoteId: latestQuote.id, policy: previewPolicy }));
        }
        else {
            return null;
        }
    }, [previewPolicyId]);
    if (!hasPolicies) {
        return React.createElement(LimitPending, null);
    }
    if (isLoading) {
        return React.createElement(QuoteLoading, { showAnimated: true, justifyCenter: true });
    }
    var sliderSettings = {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: false,
        navigation: true,
        modules: [Navigation],
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            1280: {
                slidesPerView: 3,
                spaceBetween: 30,
                centeredSlides: true
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "sticky top-0 left-0 right-0 w-full bg-white -ml-4 z-10" },
            React.createElement(PolicyStepsHeader, { steps: [
                    {
                        stepText: 'Your Details',
                        isStepActive: false,
                        isStepCompleted: true
                    },
                    {
                        stepText: 'More Questions',
                        isStepActive: false,
                        isStepCompleted: true
                    },
                    {
                        stepText: 'Coverages',
                        isStepActive: true,
                        isStepCompleted: false
                    },
                    {
                        stepText: 'Your Policy',
                        isStepActive: false,
                        isStepCompleted: false
                    },
                ], isBackDisabled: !PreviewComponent, backButtonText: "Back", handleClose: closeModal, handleBack: backHandler, hideNextButton: !previewPolicyId, handleNext: function () {
                    if (previewPolicyId) {
                        setPurchaseQuoteId(previewPolicyId);
                    }
                }, nextButtonText: "Purchase Policy" })),
        purchaseQuoteId && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "fixed z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl bg-backgroundColor rounded-lg border border-slate-200" },
                React.createElement("div", { className: "px-4 pt-2 text-left" },
                    React.createElement("button", { type: "button", onClick: closeConfirmationModal, className: "rounded-full p-2 hover:bg-gray-100 focus:bg-gray-200" },
                        React.createElement(CloseIcon, null))),
                React.createElement("div", { className: "p-6 text-left" },
                    React.createElement("form", { className: "flex flex-col gap-6", ref: formRef },
                        React.createElement(Checkbox, { id: "accept_electronic_document", label: "I have read and agree with these statements. I agree to accept\n          delivery of the insurance policy and related documents via email to\n          the address provided and agree to consent in electronic transactions.", showError: showError }),
                        React.createElement(Checkbox, { id: "wtw_policy", label: React.createElement(React.Fragment, null,
                                "An order to bind coverage presented in this proposal shall be deemed acceptance and agreement that this proposal is subject to Willis Towers Watson\u2019s Brokerage Terms, Conditions & Disclosures that are incorporated as part of this proposal and available at:",
                                ' ',
                                React.createElement("a", { href: "https://media.wtwco.com/-/media/WTW/Notices/BTCD-CRB-NA-January-2024", target: "_blank", className: "text-primary underline" }, "click here")), showError: showError }),
                        React.createElement(Checkbox, { id: "bind_agreement", label: React.createElement(React.Fragment, null, "I have read the information above, and confirm that it is correct. By checking this box and providing premium payment, I agree that I am entering into a binding agreement with WTW Midwest, LLC"), showError: showError }),
                        React.createElement("div", null,
                            React.createElement("button", { type: "button", className: "limit-purchase-button h-10 rounded-lg text-sm font-bold w-full border border-primary text-primary transition-all", onClick: function () {
                                    var form = formRef.current;
                                    var isValid = form.checkValidity();
                                    setShowError(!isValid);
                                    if (isValid) {
                                        onBindPolicy(purchaseQuoteId);
                                    }
                                } }, "Purchase"))))),
            React.createElement("div", { className: "h-screen w-screen z-10 fixed top-0 left-0 bg-black opacity-25", onClick: closeConfirmationModal }))),
        PreviewComponent ? (PreviewComponent) : (React.createElement("div", { className: "flex flex-col p-6" },
            React.createElement("div", { className: "w-full mb-12" },
                React.createElement("h2", { className: "text-[32px] text-[#1F2751] mb-2 font-bold" }, "You can review the details of each policy by clicking on \u201CDetails\u201D"),
                React.createElement("h3", { className: "text-lg text-[#1F2751]" }, "Please select the policy that best suits your needs")),
            React.createElement("div", { className: "w-full max-w-6xl mx-auto relative" },
                React.createElement(Swiper, __assign({}, sliderSettings), policies.map(function (policy) {
                    var _a, _b;
                    var prices = __spreadArray([
                        {
                            label: 'Premium',
                            amount: (((_a = policy.premium) === null || _a === void 0 ? void 0 : _a.amount) || 0) / 100
                        }
                    ], (((_b = policy === null || policy === void 0 ? void 0 : policy.fees) === null || _b === void 0 ? void 0 : _b.map(function (fee) { return ({
                        label: capitalize(fee.kind.replaceAll('_', ' ')),
                        amount: fee.fee.amount / 100
                    }); })) || []), true);
                    var total = prices.reduce(function (prev, curr) {
                        return prev + curr.amount;
                    }, 0);
                    return (React.createElement(SwiperSlide, null,
                        React.createElement(Slide, { provider: policy.market, total: total, prices: prices, onPurchase: function () {
                                setPurchaseQuoteId(policy.id);
                            }, onViewDetails: function () {
                                viewDetailsHandler(policy.id);
                            }, isRecommended: policy.is_recommended, 
                            // status={'aaa'}
                            status: policy.status })));
                })))))));
};
export default LimitPolicySelection;
