var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Suspense, useEffect, useState, useRef, memo } from 'react';
import { useQuery, useMutation, QueryClient, QueryClientProvider, } from '@tanstack/react-query';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { ErrorBoundary } from 'react-error-boundary';
import Policy from './components/Tabs/Policy/Policy';
import Coverages from './components/Tabs/Coverages/Coverages';
import QuoteInfo from './components/Tabs/QuoteInfo/QuoteInfo';
import PolicyInfo from './components/Tabs/PolicyInfo/PolicyInfo';
import PolicyInfoLastStep from './components/Tabs/PolicyInfo/PolicyInfoLastStep';
import PolicyChanges from './components/Tabs/PolicyChanges/index';
import ViewPolicyChanges from './components/Tabs/PolicyChanges/ViewChanges';
import QuotePending from './components/Tabs/Policy/Screens/QuotePending';
import api, { instance } from './api';
import { InsuranceProvider } from './context/Insurance';
import Notifications from './components/Tabs/Notifications';
import Claims from './components/Tabs/Claims';
import Modal from './components/Modal';
import ErrorComponent from './components/Error';
import useConfigStore from './stores/configStore';
import useCustomerStore from './stores/customerStore';
import useSaasPlatformStore from './stores/saasPlatformStore';
import useWidgetStore, { CurrentTabEnum } from './stores/widgetStore';
import useToken from './hooks/useToken';
import useProductStore from './stores/productStore';
import useErrorHandledMutation from './hooks/useErrorHandledMutation';
import useErrorStore from './stores/errorStore';
import useLatestQuote from './hooks/useLatestQuote';
import useLatestPolicy from './hooks/useLatestPolicy';
import useLatestQuotesWithPolicies from './hooks/useLatestQuotesWithPolicies';
import Tab from './components/Tab';
import Footer from './components/Footer';
import getStyles from './utils/getStyles';
var queryClient = new QueryClient();
var updateProductStore = function (Products, extCustomer, setProduct, style, setConfig, keys, token) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var findProduct = Products === null || Products === void 0 ? void 0 : Products.find(function (productId) { return (productId === null || productId === void 0 ? void 0 : productId.id) == extCustomer.productId; });
    setProduct({
        id: findProduct.id,
        productName: findProduct.productName,
        productParameters: findProduct.productParameters,
        productQuestionsSet: findProduct.productQuestionsSet,
        createdAt: findProduct.createdAt,
        updatedAt: findProduct.updatedAt,
        UnderwriterId: findProduct.UnderwriterId,
        ProductTypeId: findProduct.ProductTypeId,
        Underwriter: findProduct.Underwriter,
        productBranding: findProduct === null || findProduct === void 0 ? void 0 : findProduct.productBranding
    });
    var baseTheme = {
        '--theme-primary': ((_a = findProduct === null || findProduct === void 0 ? void 0 : findProduct.productBranding) === null || _a === void 0 ? void 0 : _a.primaryColor)
            ? (_b = findProduct === null || findProduct === void 0 ? void 0 : findProduct.productBranding) === null || _b === void 0 ? void 0 : _b.primaryColor
            : style.primaryColor
                ? style.primaryColor
                : 'purple',
        '--theme-secondary': ((_c = findProduct === null || findProduct === void 0 ? void 0 : findProduct.productBranding) === null || _c === void 0 ? void 0 : _c.secondaryColor)
            ? (_d = findProduct === null || findProduct === void 0 ? void 0 : findProduct.productBranding) === null || _d === void 0 ? void 0 : _d.secondaryColor
            : style.secondaryColor
                ? style.secondaryColor
                : 'white'
    };
    var root = document.getElementById('kayna');
    Object.keys(baseTheme).forEach(function (cssVar) {
        root.style.setProperty(cssVar, baseTheme[cssVar]);
    });
    setConfig({
        style: __assign(__assign({}, style), { primaryColor: ((_e = findProduct === null || findProduct === void 0 ? void 0 : findProduct.productBranding) === null || _e === void 0 ? void 0 : _e.primaryColor)
                ? (_f = findProduct === null || findProduct === void 0 ? void 0 : findProduct.productBranding) === null || _f === void 0 ? void 0 : _f.primaryColor
                : style.primaryColor
                    ? style.primaryColor
                    : 'purple', secondaryColor: ((_g = findProduct === null || findProduct === void 0 ? void 0 : findProduct.productBranding) === null || _g === void 0 ? void 0 : _g.secondaryColor)
                ? (_h = findProduct === null || findProduct === void 0 ? void 0 : findProduct.productBranding) === null || _h === void 0 ? void 0 : _h.secondaryColor
                : style.secondaryColor
                    ? style.secondaryColor
                    : 'white' }),
        keys: __assign(__assign({}, keys), { token: token })
    });
};
var App = memo(function () {
    var _a;
    var scrollableElementRef = useRef(null);
    var _b = useWidgetStore(), modalOpen = _b.modalOpen, isFormActive = _b.isFormActive;
    var _c = useWidgetStore(), currentTab = _c.currentTab, showNotification = _c.showNotification, setCurrentTab = _c.setCurrentTab, setShowNotification = _c.setShowNotification, resetWidgetState = _c.resetWidgetState, notificationSwitched = _c.notificationSwitched, setNotificationSwitched = _c.setNotificationSwitched, createQuoteSubmitted = _c.createQuoteSubmitted, setCreateQuoteSubmitted = _c.setCreateQuoteSubmitted;
    var _d = useConfigStore(), style = _d.style, keys = _d.keys, setConfig = _d.setConfig;
    var _e = useCustomerStore(), extCustomerID = _e.extCustomerID, setExternalCustomer = _e.setExternalCustomer, extCustomer = __rest(_e, ["extCustomerID", "setExternalCustomer"]);
    var _f = useSaasPlatformStore(), saasPlatformId = _f.id, Products = _f.Products, setSaasPlatformStore = _f.setSaasPlatformStore;
    var _g = useProductStore(), productId = _g.id, setProduct = _g.setProduct;
    var errorHandledMutation = useErrorHandledMutation().errorHandledMutation;
    var _h = useToken(), generatedWithCustomer = _h.generatedWithCustomer, refreshToken = _h.refreshToken, token = _h.token, tokenLoading = _h.tokenLoading;
    var _j = useErrorStore(), error = _j.error, setError = _j.setError, setConfigError = _j.setConfigError, configError = _j.configError;
    var latestQuotes = useLatestQuotesWithPolicies();
    var latestQuote = useLatestQuote();
    var latestPolicy = useLatestPolicy();
    var _k = useState(false), fetchProduct = _k[0], setFetchProduct = _k[1];
    var createNewQuote = useMutation(api.quote.create, {
        onSuccess: function () {
            queryClient.invalidateQueries(['customer']);
        }
    });
    var createNewQuoteNotification = useMutation(api.quote.createNotification, {
        onSuccess: function () {
            queryClient.invalidateQueries(['customer']);
        }
    });
    var createQuoteMutation = function (customerID, items, productId, quoteExternalId) {
        return errorHandledMutation(function () {
            return createNewQuote.mutateAsync({
                customerID: customerID,
                productId: productId,
                items: {
                    requiredParams: items,
                    otherParams: {}
                },
                saasId: keys.platformId,
                externalID: extCustomerID,
                quoteExternalId: quoteExternalId
            });
        });
    };
    var createQuoteNotificationMutation = function (customerID, externalId, productId, items, policyId) {
        return errorHandledMutation(function () {
            return createNewQuoteNotification.mutateAsync({
                customerID: customerID,
                productId: productId,
                items: {
                    requiredParams: items,
                    otherParams: {}
                },
                saasId: keys.platformId,
                externalID: extCustomerID,
                quoteExternalId: externalId,
                policyId: policyId
            });
        });
    };
    var createCustomer = useMutation(api.customer.create, {
        onSuccess: function (customer) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                // customerState.customerId = customer?.data?.data?.customer_id;
                //@ts-ignore
                setExternalCustomer({
                    id: (_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id
                });
                // if (!generatedWithCustomer) {
                // await refreshToken();
                // setFetchProduct(true);
                // }
                queryClient.invalidateQueries(['customer']);
                return [2 /*return*/];
            });
        }); },
        onError: function (err) {
            setError({ error: err === null || err === void 0 ? void 0 : err.message });
        }
    });
    var customer = useQuery(['customer'], function () { return api.customer.get(extCustomerID, keys.platformId); }, {
        enabled: keys.token ? true : false,
        retry: 0,
        onError: function (err) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!(((_b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.messageCode) === 'no-content')) return [3 /*break*/, 2];
                        // create new customer
                        // if (keys.widgetType === 'kayna') {
                        return [4 /*yield*/, errorHandledMutation(function () {
                                return createCustomer.mutateAsync({
                                    // companyName: extCustomer.extCustomerName,
                                    customerName: extCustomer.extCustomerName,
                                    customerEmail: extCustomer.extCustomerEmail,
                                    // contactPersonEmail: extCustomer.extCustomerEmail,
                                    // phone: '123453',
                                    // website: 'widget.kayna.io',
                                    externalID: extCustomerID,
                                    platformId: keys.platformId
                                });
                            })];
                    case 1:
                        // create new customer
                        // if (keys.widgetType === 'kayna') {
                        _d.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        if (((_c = err === null || err === void 0 ? void 0 : err.message) === null || _c === void 0 ? void 0 : _c.includes('403')) ||
                            (err === null || err === void 0 ? void 0 : err.code) == '403' ||
                            JSON.stringify(err) == JSON.stringify({})) {
                            setError({
                                error: 'Oops! 😔 It seems your token has expired. Please refresh and try again.'
                            });
                        }
                        else {
                            setError({
                                error: 'Oops! 😞 An error occurred. Please refresh and try again.'
                            });
                        }
                        _d.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function (res) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            return __awaiter(this, void 0, void 0, function () {
                var usedProductIds, invalidProductIds, firstProduct, productId_1, baseTheme_1, root_1, newStyles;
                return __generator(this, function (_x) {
                    switch (_x.label) {
                        case 0:
                            //@ts-ignore
                            setExternalCustomer({
                                id: (_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id
                            });
                            if (createQuoteSubmitted) {
                                setCreateQuoteSubmitted(false);
                            }
                            setSaasPlatformStore((_d = (_c = res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.SaasPlatform);
                            if (!!generatedWithCustomer) return [3 /*break*/, 2];
                            return [4 /*yield*/, refreshToken()];
                        case 1:
                            _x.sent();
                            _x.label = 2;
                        case 2:
                            usedProductIds = {};
                            Object.values(extCustomer.applicationFormData || {}).forEach(function (formData) {
                                usedProductIds[formData.productId] = true;
                            });
                            (_g = (_f = (_e = res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.SaasPlatform) === null || _g === void 0 ? void 0 : _g.Products.forEach(function (prod) {
                                delete usedProductIds[prod.id];
                            });
                            invalidProductIds = Object.keys(usedProductIds);
                            if (invalidProductIds.length > 0) {
                                setConfigError(true);
                                throw new Error("You are using product IDs ".concat(invalidProductIds.map(function (id) { return "\"".concat(id, "\""); }), " which are not supported by platform you are using"));
                            }
                            firstProduct = null;
                            if (extCustomer.applicationFormData) {
                                productId_1 = (_j = (_h = Object.values(extCustomer.applicationFormData)) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.productId;
                                if (productId_1) {
                                    firstProduct =
                                        ((_o = (_m = (_l = (_k = res.data) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.SaasPlatform) === null || _m === void 0 ? void 0 : _m.Products) === null || _o === void 0 ? void 0 : _o.find(function (v) { return v.id == productId_1; })) || null;
                                }
                            }
                            if (firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) {
                                baseTheme_1 = {
                                    '--theme-primary': ((_p = firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) === null || _p === void 0 ? void 0 : _p.primaryColor)
                                        ? (_q = firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) === null || _q === void 0 ? void 0 : _q.primaryColor
                                        : style.primaryColor
                                            ? style.primaryColor
                                            : 'purple',
                                    '--theme-secondary': ((_r = firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) === null || _r === void 0 ? void 0 : _r.secondaryColor)
                                        ? (_s = firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) === null || _s === void 0 ? void 0 : _s.secondaryColor
                                        : style.secondaryColor
                                            ? style.secondaryColor
                                            : 'white'
                                };
                                root_1 = document.getElementById('kayna');
                                Object.keys(baseTheme_1).forEach(function (cssVar) {
                                    root_1.style.setProperty(cssVar, baseTheme_1[cssVar]);
                                });
                                newStyles = __assign(__assign({}, style), { primaryColor: ((_t = firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) === null || _t === void 0 ? void 0 : _t.primaryColor)
                                        ? (_u = firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) === null || _u === void 0 ? void 0 : _u.primaryColor
                                        : style.primaryColor
                                            ? style.primaryColor
                                            : 'purple', secondaryColor: ((_v = firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) === null || _v === void 0 ? void 0 : _v.secondaryColor)
                                        ? (_w = firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) === null || _w === void 0 ? void 0 : _w.secondaryColor
                                        : style.secondaryColor
                                            ? style.secondaryColor
                                            : 'white' });
                                setConfig({
                                    style: newStyles
                                });
                                setProduct({
                                    productBranding: (firstProduct === null || firstProduct === void 0 ? void 0 : firstProduct.productBranding) || null
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // const product = useQuery(
    //   ['product', token],
    //   () => api.product.get(extCustomer.productId),
    //   {
    //     enabled: fetchProduct,
    //     retry: 0,
    //     onError: async (err: AxiosError) => {
    //       if (
    //         err?.message?.includes('403') ||
    //         err?.code == '403' ||
    //         JSON.stringify(err) == JSON.stringify({})
    //       ) {
    //         setError({
    //           error:
    //             'Oops! 😔 It seems your token has expired. Please refresh and try again.',
    //         });
    //       } else {
    //         setError({
    //           error: 'Oops! 😞 An error occurred. Please refresh and try again.',
    //         });
    //       }
    //     },
    //     async onSuccess(res: AxiosResponse) {
    //       setError({ error: null });
    //       setProduct(res.data.data);
    //       const baseTheme = {
    //         '--theme-primary': res.data?.data?.productBranding?.primaryColor
    //           ? res.data?.data?.productBranding?.primaryColor
    //           : style.primaryColor
    //           ? style.primaryColor
    //           : 'purple',
    //         '--theme-secondary': res.data?.data?.productBranding?.secondaryColor
    //           ? res.data?.data?.productBranding?.secondaryColor
    //           : style.secondaryColor
    //           ? style.secondaryColor
    //           : 'white',
    //       };
    //       const root = document.getElementById('kayna');
    //       Object.keys(baseTheme).forEach((cssVar) => {
    //         root.style.setProperty(cssVar, baseTheme[cssVar]);
    //       });
    //       setConfig({
    //         style: {
    //           ...style,
    //           primaryColor: res.data?.data?.productBranding?.primaryColor
    //             ? res.data?.data?.productBranding?.primaryColor
    //             : style.primaryColor
    //             ? style.primaryColor
    //             : 'purple',
    //           secondaryColor: res.data?.data?.productBranding?.secondaryColor
    //             ? res.data?.data?.productBranding?.secondaryColor
    //             : style.secondaryColor
    //             ? style.secondaryColor
    //             : 'white',
    //         },
    //         keys: keys,
    //       });
    //     },
    //   },
    // );
    var generateQuote = function (items, productId, quoteExternalId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createQuoteMutation(customer.data.data.id
                            ? customer.data.data.id
                            : customer.data.data.data.id, items, productId, quoteExternalId)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.log('Error generating new quote.', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var generateNewNotificationQuote = function (externalId, productId, items, policyId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createQuoteNotificationMutation(customer.data.data.id
                            ? customer.data.data.id
                            : customer.data.data.data.id, externalId, productId, items, policyId)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.log('Error generating new quote.', error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // useEffect(() => {
    //   if (!saasPlatformId || productId || !extCustomer.productId) {
    //     return;
    //   }
    //   updateProductStore(
    //     Products,
    //     extCustomer,
    //     setProduct,
    //     style,
    //     setConfig,
    //     keys,
    //     token,
    //   );
    // }, [saasPlatformId, productId, extCustomer, style]);
    // useEffect(() => {
    //   if (!generatedWithCustomer || !latestQuote?.items || !extCustomer?.items) {
    //     return;
    //   }
    //   latestQuotes.map((quote) => {
    //     const applicationData = cloneDeep(
    //       extCustomer.applicationFormData[quote.externalId]?.applicationDetails,
    //     );
    //     const applicationDataToCheck =
    //       quote?.MTDraftQuotes?.length > 0
    //         ? quote.MTDraftQuotes[
    //             getLatestPolicyOrQuote(quote.MTDraftQuotes, true)
    //           ].items.requiredParams
    //         : quote?.items?.requiredParams;
    //     const equal = deepEqual(applicationDataToCheck, applicationData);
    //     if (!equal) {
    //       if (quote.policy && quote.policy.id) {
    //         generateNewNotificationQuote(
    //           quote.externalId,
    //           quote.ProductId,
    //           applicationData,
    //           quote.policy.id,
    //         );
    //       } else {
    //         generateQuote(applicationData, quote.ProductId, quote.externalId);
    //       }
    //     }
    //   });
    //   // const equal = deepEqual(
    //   //   latestQuote?.items?.requiredParams,
    //   //   extCustomer.applicationFormData,
    //   // );
    //   // //@ts-ignore
    //   // if (!equal) {
    //   //   if (!latestPolicy) {
    //   //     generateQuote();
    //   //   } else {
    //   //     generateNewNotificationQuote();
    //   //   }
    //   // }
    // }, [latestQuotes, generatedWithCustomer]);
    useEffect(function () {
        var _a, _b;
        if (!isFormActive &&
            !modalOpen &&
            (((_a = latestQuotes === null || latestQuotes === void 0 ? void 0 : latestQuotes.filter(function (q) { var _a; return ((_a = q.MTDraftQuotes) === null || _a === void 0 ? void 0 : _a.length) > 0; })) === null || _a === void 0 ? void 0 : _a.length) > 0 ||
                ((_b = latestQuotes === null || latestQuotes === void 0 ? void 0 : latestQuotes.filter(function (q) { var _a; return ((_a = q.otherQuotes) === null || _a === void 0 ? void 0 : _a.length) > 0; })) === null || _b === void 0 ? void 0 : _b.length) > 0)) {
            // setShowNotification(false);
            setShowNotification(true);
            if (!notificationSwitched) {
                setCurrentTab(CurrentTabEnum.NOTIFICATIONS);
                setNotificationSwitched(true);
            }
            // if (latestPolicy) {
            //   const latestQuoteDate = new Date(latestQuote?.createdAt);
            //   const latestPolicyDate = new Date(latestPolicy?.createdAt);
            //   const compareResult = compareAsc(latestQuoteDate, latestPolicyDate);
            //   if (compareResult === 1) {
            //     setShowNotification(true);
            //     setCurrentTab(CurrentTabEnum.NOTIFICATIONS);
            //   }
            // } else if (
            //   customer?.data?.data?.data?.Quotes?.filter(
            //     (quote) =>
            //       quote?.status !== 'rejected' && quote?.status !== 'pending',
            //   )?.length > 1 &&
            //   !latestPolicy
            // ) {
            //   setShowNotification(true);
            //   setCurrentTab(CurrentTabEnum.NOTIFICATIONS);
            // }
        }
    }, [latestQuotes]);
    useEffect(function () {
        var _a, _b;
        if (extCustomerID !== ((_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.externalId)) {
            queryClient.clear();
            resetWidgetState();
        }
    }, [extCustomerID]);
    useEffect(function () {
        if (keys.token) {
            instance.defaults.headers.common['authorization'] = keys.token;
        }
    }, [keys.token]);
    var getTabComponent = function (scrollableElementRef) {
        switch (currentTab) {
            case CurrentTabEnum.POLICY:
                return (React.createElement(Policy, { scrollableElementRef: scrollableElementRef, customer: customer }));
            case CurrentTabEnum.CLAIMS:
                return (React.createElement(Claims
                // primaryColor={style.primaryColor}
                // customerID={Number(extCustomer.customerId)}
                // setOpenModal={props.setOpenModal}
                // propsClaims={customerState.claims}
                // initiailzedPlatformClaims={initiailzedPlatformClaims}
                // setInitiailzedPlatformClaims={setInitiailzedPlatformClaims}
                , null));
            case CurrentTabEnum.NOTIFICATIONS:
                return React.createElement(Notifications, { show: showNotification });
            case CurrentTabEnum.QUOTE_INFO:
                return React.createElement(QuoteInfo, null);
            case CurrentTabEnum.POLICY_INFO:
                return React.createElement(PolicyInfo, null);
            case CurrentTabEnum.COVERAGES:
                return React.createElement(Coverages, { showHeader: true });
            case CurrentTabEnum.POLICY_INFO_LAST_STEP:
                return React.createElement(PolicyInfoLastStep, null);
            case CurrentTabEnum.QUOTE_PENDING:
                return React.createElement(QuotePending, null);
            case CurrentTabEnum.POLICY_CHANGES:
                return React.createElement(PolicyChanges, null);
            case CurrentTabEnum.VIEW_POLICY_CHANGES:
                return React.createElement(ViewPolicyChanges, null);
            default:
                return React.createElement(Policy, null);
        }
    };
    React.useEffect(function () {
        var _a, _b, _c;
        var baseTheme = {
            '--theme-primary': style.primaryColor ? style.primaryColor : 'purple',
            '--theme-secondary': style.secondaryColor
                ? style.secondaryColor
                : 'white',
            '--theme-bg-color': (_a = getStyles(style.theme)) === null || _a === void 0 ? void 0 : _a.backgroundColor,
            '--theme-text-color': (_b = getStyles(style.theme)) === null || _b === void 0 ? void 0 : _b.textColor,
            '--theme-loading-color': (_c = getStyles(style.theme)) === null || _c === void 0 ? void 0 : _c.loadingColor
        };
        var root = document.getElementById('kayna');
        Object.keys(baseTheme).forEach(function (cssVar) {
            root.style.setProperty(cssVar, baseTheme[cssVar]);
        });
    }, [style.primaryColor, style.secondaryColor, style.theme]);
    if (configError) {
        return React.createElement(ErrorComponent, { style: style });
    }
    return (React.createElement("div", { className: "m-auto flex flex-col align-center justify-center relative overflow-hidden kayna-app bg-backgroundColor ".concat(modalOpen
            ? 'w-screen h-screen sm:w-[95vw] sm:h-[95vh] rounded-none sm:rounded-lg'
            : "w-[".concat(style.width, "] h-[").concat(style.height, "] rounded-3xl")), style: {
            width: !modalOpen && style.width,
            height: !modalOpen && style.height,
            border: "1px solid ".concat((_a = getStyles(style.theme)) === null || _a === void 0 ? void 0 : _a.widgetBorderColor)
        } },
        !modalOpen && (React.createElement("div", { className: "pb-2" },
            React.createElement(Suspense, { fallback: React.createElement("div", { className: "h-5", style: { backgroundColor: '#F8F8F9' } }) },
                React.createElement("ul", { className: "flex mb-0 list-none flex-row bg-backgroundColor", role: "tablist", style: {
                        width: style.width
                    } },
                    React.createElement(Tab, { name: "Your Policy", number: 1, isLeftAligned: !latestQuote }),
                    latestQuote && (React.createElement(Tab, { number: 3, icon: faBell, disabled: !showNotification })))))),
        React.createElement("div", { className: "antialiased text-center bg-backgroundColor bg-opacity-40 relative w-full h-full flex flex-col justify-start overflow-y-scroll", ref: scrollableElementRef, id: "containerId" }, getTabComponent(scrollableElementRef)),
        !error && (React.createElement("div", { className: "w-full" },
            React.createElement(Suspense, { fallback: React.createElement("div", { className: "h-5", style: { backgroundColor: '#F8F8F9' } }) },
                React.createElement(Footer, null))))));
});
var QueryWrappedApp = function (props) {
    var setConfig = useConfigStore().setConfig;
    var setExternalCustomer = useCustomerStore().setExternalCustomer;
    useEffect(function () {
        setExternalCustomer(__assign({}, props.customer));
    }, [props.customer]);
    useEffect(function () {
        var newConfig = __assign(__assign({}, props.config.style), { theme: props.config.style.theme ? props.config.style.theme : 'default' });
        setConfig({ style: newConfig, keys: props.config.keys });
    }, [props.config]);
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(InsuranceProvider, null,
            React.createElement(ErrorBoundary, { fallback: React.createElement(ErrorComponent, { style: props.config.style }) },
                React.createElement(Modal, null,
                    React.createElement(App, null))))));
};
export default QueryWrappedApp;
