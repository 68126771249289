import React, { useMemo } from 'react';
import useWidgetStore, { CurrentTabEnum } from '../../../stores/widgetStore';
import useConfigStore from '../../../stores/configStore';
import QuoteLoading from '../Policy/QuoteLoading';
import { useQueryClient } from '@tanstack/react-query';
import useLatestQuotesWithPolicies from '../../../hooks/useLatestQuotesWithPolicies';
import PolicyStepsHeader from '../../common/PolicyStepsHeader';
import MoneyMinusIcon from '../../icons/MoneyMinusIcon';
import MoneyTagIcon from '../../icons/MoneyTagIcon';
import MoneyWalletIcon from '../../icons/MoneyWalletIcon';
import DownloadIcon from '../../icons/DownloadIcon';
import LimitPolicyPreview from '../Policy/LimitPolicyPreview';
import getPolicyInfo from '../../../utils/getPolicyInfo';
var PolicyInfo = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var queryClient = useQueryClient();
    var customer = queryClient.getQueryData(['customer']);
    var latestQuotesWithPolicies = useLatestQuotesWithPolicies(true);
    var _p = useWidgetStore(), selectedQuote = _p.selectedQuote, setSelectedQuote = _p.setSelectedQuote, setCurrentTab = _p.setCurrentTab, setModalOpen = _p.setModalOpen;
    var _q = useMemo(function () {
        var latestQuote = latestQuotesWithPolicies.find(function (q) { return q.id === selectedQuote; }) || null;
        var latestPolicy = (latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.policy) || null;
        return { latestQuote: latestQuote, latestPolicy: latestPolicy };
    }, [latestQuotesWithPolicies, selectedQuote]), latestQuote = _q.latestQuote, latestPolicy = _q.latestPolicy;
    var widgetType = useConfigStore().keys.widgetType;
    var updateTab = function (e, number) {
        e.preventDefault();
        setCurrentTab(number);
    };
    if (!latestPolicy) {
        return React.createElement(QuoteLoading, null);
    }
    var closeModalHandler = function () {
        setSelectedQuote(null);
        setModalOpen(false);
        setCurrentTab(CurrentTabEnum.POLICY);
    };
    return (React.createElement("div", { className: "flex flex-col items-center sm:px-4" },
        React.createElement("div", { className: "sticky top-0 left-0 right-0 w-full bg-white -ml-4 z-10" },
            React.createElement(PolicyStepsHeader, { pageName: "Your Policy", hideNextButton: true, handleClose: closeModalHandler })),
        ((_b = (_a = latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.product) === null || _a === void 0 ? void 0 : _a.Underwriter) === null || _b === void 0 ? void 0 : _b.widgetFlow) === 'limit' &&
            latestPolicy ? (React.createElement("div", { className: "py-8" },
            React.createElement(LimitPolicyPreview, { quoteId: latestQuote.id, policy: latestPolicy.partnerData }))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "w-full rounded-2xl bg-[#F8FAFE] p-1 flex items-stretch my-6" },
                React.createElement("button", { className: "text-[16px] text-primary bg-white font-medium py-3 rounded-2xl w-full" }, "Details"),
                React.createElement("button", { className: "text-[16px] text-[#A5A9B9] font-medium py-3 rounded-2xl w-full", onClick: function (e) { return updateTab(e, CurrentTabEnum.COVERAGES); } }, "Coverages")),
            React.createElement("div", { className: "grid grid-cols-1 gap-y-1 gap-x-4 w-full sm:grid-cols-2 sm:grid-rows-3 sm:gap-y-2" }, (_c = getPolicyInfo(latestPolicy, latestQuote).policyDetails) === null || _c === void 0 ? void 0 : _c.map(function (item) {
                return (React.createElement("div", { className: "grid grid-cols-2 gap-x-1 sm:grid-cols-3 sm:gap-x-2" },
                    React.createElement("span", { className: "text-[16px] bg-[#F8FAFE] py-4 pl-4 text-left flex items-center capitalize sm:col-span-2 sm:rounded-l-2xl sm:py-2" }, item.name),
                    React.createElement("span", { className: "text-[16px] font-semibold text-[#1F2751] bg-[#F8FAFE] py-4 flex items-center justify-center capitalize sm:rounded-l sm:rounded-r-2xl sm:py-2" }, item.value)));
            })),
            React.createElement("div", { className: "w-full flex flex-col items-center px-4 sm:px-0" },
                React.createElement("div", { className: "w-full p-6 rounded-2xl border-2 border-[#E0E5F2] bg-[#F8FAFE] flex flex-col my-8 gap-y-4 sm:items-center sm:flex-row sm:justify-around sm:p-4 sm:gap-y-0" },
                    React.createElement("div", { className: "flex items-center justify-between sm:px-3 sm:justify-start" },
                        React.createElement(MoneyMinusIcon, null),
                        React.createElement("span", { className: "text-[16px] ml-4 mr-auto sm:mr-0" }, "Yearly Premium"),
                        React.createElement("span", { className: "text-[16px] font-semibold ml-2" }, (_e = (_d = getPolicyInfo(latestPolicy, latestQuote)) === null || _d === void 0 ? void 0 : _d.quoteDetails) === null || _e === void 0 ? void 0 : _e.yearlyPremium)),
                    React.createElement("div", { className: "w-[1px] h-full bg-[#E0E5F2]" }),
                    React.createElement("div", { className: "flex items-center justify-between sm:px-3 sm:justify-start" },
                        React.createElement(MoneyTagIcon, null),
                        React.createElement("span", { className: "text-[16px] ml-4 mr-auto sm:mr-0" }, "IPT"),
                        React.createElement("span", { className: "text-[16px] font-semibold ml-2" }, (_g = (_f = getPolicyInfo(latestPolicy, latestQuote)) === null || _f === void 0 ? void 0 : _f.quoteDetails) === null || _g === void 0 ? void 0 : _g.ipt)),
                    React.createElement("div", { className: "w-[1px] h-full bg-[#E0E5F2]" }),
                    React.createElement("div", { className: "flex items-center justify-between sm:px-3 sm:justify-start" },
                        React.createElement(MoneyWalletIcon, { primaryColor: true }),
                        React.createElement("span", { className: "text-[16px] ml-4 mr-auto sm:mr-0" }, "Total Payable Amount"),
                        React.createElement("span", { className: "text-[16px] font-semibold ml-2" }, (_j = (_h = getPolicyInfo(latestPolicy, latestQuote)) === null || _h === void 0 ? void 0 : _h.quoteDetails) === null || _j === void 0 ? void 0 : _j.total))),
                React.createElement("div", { className: "flex flex-col items-center gap-y-4 w-full sm:flex-row sm:gap-x-4" },
                    React.createElement("button", { className: "w-full p-3 rounded-2xl bg-[#F8FAFE] border-2 border-[#E0E5F2]" },
                        React.createElement("a", { className: "w-full flex items-center justify-between cursor-pointer", href: (_l = (_k = getPolicyInfo(latestPolicy, latestQuote)) === null || _k === void 0 ? void 0 : _k.policyDoc) === null || _l === void 0 ? void 0 : _l.policyDocuemnt, target: "_blank" },
                            React.createElement("span", { className: "text-[16px] font-semibold text-[#1F2751]" }, "Policy Document"),
                            React.createElement("span", { className: "bg-[#F0F0FE] p-2 rounded-xl" },
                                React.createElement(DownloadIcon, { primaryColor: true })))),
                    React.createElement("button", { className: "w-full p-3 rounded-2xl bg-[#F8FAFE] border-2 border-[#E0E5F2]" },
                        React.createElement("a", { className: "w-full flex items-center justify-between cursor-pointer", href: (_o = (_m = getPolicyInfo(latestPolicy, latestQuote)) === null || _m === void 0 ? void 0 : _m.policyDoc) === null || _o === void 0 ? void 0 : _o.certificateOfInsurance, target: "_blank" },
                            React.createElement("span", { className: "text-[16px] font-semibold text-[#1F2751]" }, "Certificate of Insurance"),
                            React.createElement("span", { className: "bg-[#F0F0FE] p-2 rounded-xl" },
                                React.createElement(DownloadIcon, { primaryColor: true }))))))))));
};
export default PolicyInfo;
