var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, memo, useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useConfigStore from '../../../../stores/configStore';
import useProductStore from '../../../../stores/productStore';
import useErrorHandledMutation from '../../../../hooks/useErrorHandledMutation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '../../../../api';
import useCustomerStore from '../../../../stores/customerStore';
import QuoteLoading from '../../../Tabs/Policy/QuoteLoading';
import useWidgetStore from '../../../../stores/widgetStore';
import PolicyFormInput from '../../../common/PolicyFormInput';
import converter from 'number-to-words';
import pluralize from 'pluralize';
import useLatestQuote from '../../../../hooks/useLatestQuote';
import PolicyStepsHeader from '../../../common/PolicyStepsHeader';
import PolicyFormProgressBar from '../../../common/PolicyFormProgressBar';
import useErrorStore from '../../../../stores/errorStore';
import useSaasPlatformStore from '../../../../stores/saasPlatformStore';
function camelToWords(camelcase) {
    if (/[a-z][A-Z]/.test(camelcase)) {
        return camelcase.replace(/([A-Z])/g, ' $1').trim();
    }
    else {
        return camelcase;
    }
}
function findProperty(obj, targetProperty) {
    for (var key in obj) {
        if (typeof obj[key] === 'object') {
            var result = findProperty(obj[key], targetProperty);
            if (result) {
                return result;
            }
        }
        else if (key === targetProperty) {
            return obj[key];
        }
    }
    return null;
}
var createValidationSchema = function (questionGroup, questions) {
    var _a, _b, _c;
    if (!questionGroup || !questions)
        return;
    var validations = {};
    for (var i = 0; i < ((_b = (_a = questions[questionGroup]) === null || _a === void 0 ? void 0 : _a.questions) === null || _b === void 0 ? void 0 : _b.length); i++) {
        var currentQuestion = (_c = questions[questionGroup]) === null || _c === void 0 ? void 0 : _c.questions[i];
        var condition = Yup.string();
        if (['select', 'string', 'text'].includes(currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase())) {
            condition = Yup.string();
        }
        if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase()) == 'url') {
            condition = Yup.string().url('Please enter valid url');
        }
        if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase()) == 'number') {
            condition = Yup.number();
        }
        if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase()) == 'email') {
            condition = Yup.string().email();
        }
        if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase()) == 'radio') {
            condition = Yup.string();
        }
        if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase()) == 'date') {
            condition = Yup.date();
        }
        if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase()) == 'money') {
            condition = Yup.string().test('test-money', 'Must be atleast 1', function (val) {
                try {
                    var valNum = Number("".concat(val).replace(/\D/g, ''));
                    return valNum > 0;
                }
                catch (e) {
                    return false;
                }
            });
        }
        if (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.required) {
            if (['string', 'text'].includes(currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase())) {
                condition = condition.trim('required');
            }
            condition = condition.required('required');
        }
        if (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.min) {
            if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase()) == 'date') {
                condition = condition.min(new Date(currentQuestion.min));
            }
            else {
                condition = condition.min(currentQuestion.min);
            }
        }
        if (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.max) {
            if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.inputType.toLowerCase()) == 'date') {
                condition = condition.max(new Date(currentQuestion.max));
            }
            else {
                condition = condition.max(currentQuestion.max);
            }
        }
        validations[currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.questionKey] = condition;
    }
    var yupSchema = Yup.object().shape(validations);
    return yupSchema;
};
var getInitialValues = function (questionGroup, questions, answers) {
    var _a, _b;
    if (!questionGroup || !questions)
        return null;
    if (answers === null || answers === void 0 ? void 0 : answers[questionGroup]) {
        return answers === null || answers === void 0 ? void 0 : answers[questionGroup];
    }
    var defaultValues = {};
    (_b = (_a = questions[questionGroup]) === null || _a === void 0 ? void 0 : _a.questions) === null || _b === void 0 ? void 0 : _b.forEach(function (v) {
        if (v.value) {
            if (v.inputType === 'date') {
                v.value = new Date(v.value);
            }
            defaultValues[v.questionKey] = v.value;
        }
        else {
            defaultValues[v.questionKey] = null;
        }
    });
    return Object.keys(defaultValues).length == 0 ? null : defaultValues;
};
var handleSubmit = function (values, answers, setAnswers, questions, questionLast, findQuestionsWithConditions, currQuestionGroup, setCurrQuestionGroup, setNavigateHistory, navigateHistory, setQuestionLast, scrollableElementRef, createQuoteMutation, updateQuoteMutation, latestQuote, customerId, formik, applicationDataId) {
    var _a, _b;
    var _c;
    setAnswers(__assign(__assign({}, answers), (_a = {}, _a[Object.keys(questions)[currQuestionGroup]] = __assign(__assign({}, answers === null || answers === void 0 ? void 0 : answers[Object.keys(questions)[currQuestionGroup]]), values), _a)));
    var requiredParams = __assign(__assign({}, answers), (_b = {}, _b[Object.keys(questions)[currQuestionGroup]] = __assign(__assign({}, answers === null || answers === void 0 ? void 0 : answers[Object.keys(questions)[currQuestionGroup]]), values), _b));
    if (scrollableElementRef.current) {
        scrollableElementRef.current.scrollTop = 0;
    }
    if (!questionLast) {
        var questionFilter = findQuestionsWithConditions(currQuestionGroup + 1);
        if (questionFilter) {
            setCurrQuestionGroup(questionFilter);
            setNavigateHistory(__spreadArray(__spreadArray([], navigateHistory, true), [questionFilter], false));
            if (((_c = Object.keys(questions)) === null || _c === void 0 ? void 0 : _c.length) - 1 === questionFilter) {
                setQuestionLast(true);
            }
            if (getInitialValues(Object.keys(questions)[questionFilter], questions, answers)) {
                formik.setValues(getInitialValues(Object.keys(questions)[questionFilter], questions, answers));
            }
        }
    }
    else {
        // setModalOpen(false);
        if (latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.cerityQuoteId) {
            updateQuoteMutation({
                requiredParams: __assign(__assign({}, latestQuote.items.requiredParams), answers),
                otherParams: {}
            }, customerId);
        }
        else {
            createQuoteMutation({
                requiredParams: requiredParams,
                otherParams: {}
            }, customerId, applicationDataId);
        }
        setCurrQuestionGroup(0);
    }
};
var FormSection = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var modalOpen = _a.modalOpen, currQuestionGroup = _a.currQuestionGroup, questionLast = _a.questionLast, questions = _a.questions, skippedGroups = _a.skippedGroups, answers = _a.answers, removedQuestions = _a.removedQuestions, setRemovedQuestions = _a.setRemovedQuestions, setAnswers = _a.setAnswers, findQuestionsWithConditions = _a.findQuestionsWithConditions, setCurrQuestionGroup = _a.setCurrQuestionGroup, setNavigateHistory = _a.setNavigateHistory, navigateHistory = _a.navigateHistory, setQuestionLast = _a.setQuestionLast, scrollableElementRef = _a.scrollableElementRef, createQuoteMutation = _a.createQuoteMutation, updateQuoteMutation = _a.updateQuoteMutation, latestQuote = _a.latestQuote, customerId = _a.customerId, resetFormState = _a.resetFormState, applicationDataId = _a.applicationDataId, setRestartRequest = _a.setRestartRequest, 
    // setProductId,
    setApplicationDataId = _a.setApplicationDataId, setQuestionsStart = _a.setQuestionsStart, setSkippedGroups = _a.setSkippedGroups, setQuestions = _a.setQuestions, setProduct = _a.setProduct, setFormActive = _a.setFormActive;
    var queryClient = useQueryClient();
    var customer = (_b = queryClient.getQueryState(['customer'])) === null || _b === void 0 ? void 0 : _b.data;
    var applicationFormData = useCustomerStore().applicationFormData;
    var formik = useFormik({
        initialValues: getInitialValues(Object.keys(questions)[currQuestionGroup], questions, answers),
        onSubmit: function (values) {
            handleSubmit(values, answers, setAnswers, questions, questionLast, findQuestionsWithConditions, currQuestionGroup, setCurrQuestionGroup, setNavigateHistory, navigateHistory, setQuestionLast, scrollableElementRef, createQuoteMutation, updateQuoteMutation, latestQuote, customerId, formik, applicationDataId);
        },
        validationSchema: createValidationSchema((_c = Object.keys(questions)) === null || _c === void 0 ? void 0 : _c[currQuestionGroup], questions)
    });
    var handleBack = function () {
        var _a, _b;
        setRemovedQuestions(__assign(__assign({}, removedQuestions), (_a = {}, _a[Object.keys(questions)[currQuestionGroup]] = [], _a)));
        setCurrQuestionGroup(navigateHistory[navigateHistory.length - 2]);
        formik.setValues(getInitialValues(Object.keys(questions)[navigateHistory[navigateHistory.length - 2]], questions, answers));
        setNavigateHistory(navigateHistory.slice(0, navigateHistory.length - 1));
        setQuestionLast(false);
        setAnswers(__assign(__assign({}, answers), (_b = {}, _b[Object.keys(questions)[currQuestionGroup]] = __assign(__assign({}, answers === null || answers === void 0 ? void 0 : answers[Object.keys(questions)[currQuestionGroup]]), formik.values), _b)));
    };
    var widgetFlow = useMemo(function () {
        var _a, _b, _c, _d, _e, _f;
        var productId = (_a = applicationFormData === null || applicationFormData === void 0 ? void 0 : applicationFormData[applicationDataId]) === null || _a === void 0 ? void 0 : _a.productId;
        var product = (_e = (_d = (_c = (_b = customer === null || customer === void 0 ? void 0 : customer.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.SaasPlatform) === null || _d === void 0 ? void 0 : _d.Products) === null || _e === void 0 ? void 0 : _e.find(function (p) { return p.id === productId; });
        return (_f = product === null || product === void 0 ? void 0 : product.Underwriter) === null || _f === void 0 ? void 0 : _f.widgetFlow;
    }, [applicationFormData, applicationDataId, customer]);
    return (React.createElement("form", { className: modalOpen
            ? 'w-[98vw] h-[92vh] absolute top-0 flex flex-col items-center justify-start overflow-hidden sm:w-full sm:h-[95%]'
            : 'w-[80%]', onSubmit: function (e) {
            e.preventDefault();
            formik.handleSubmit();
        } },
        React.createElement("div", { className: "w-full shadow-sm" },
            React.createElement(PolicyStepsHeader, { steps: __spreadArray(__spreadArray([
                    {
                        stepText: 'Your Details',
                        isStepActive: true,
                        isStepCompleted: false
                    }
                ], (widgetFlow === 'limit'
                    ? [
                        {
                            stepText: 'More Questions',
                            isStepActive: false,
                            isStepCompleted: false
                        },
                    ]
                    : []), true), [
                    {
                        stepText: 'Coverages',
                        isStepActive: false,
                        isStepCompleted: false
                    },
                    {
                        stepText: 'Your Policy',
                        isStepActive: false,
                        isStepCompleted: false
                    },
                ], false), nextButtonText: questionLast ? 'Submit' : 'Next', backButtonText: "Back", isBackDisabled: currQuestionGroup === 0, handleBack: function () {
                    if (currQuestionGroup == 0) {
                        setRestartRequest(false);
                        setAnswers(null);
                        // setProductId(null);
                        setApplicationDataId(null);
                        setQuestionLast(false);
                        setQuestionsStart(true);
                        setCurrQuestionGroup(0);
                        setNavigateHistory([]);
                        setSkippedGroups(0);
                        setQuestions(null);
                        setRemovedQuestions(null);
                        setProduct({
                            id: null,
                            productName: null,
                            productParameters: null,
                            productQuestionsSet: null,
                            createdAt: null,
                            updatedAt: null,
                            UnderwriterId: null,
                            ProductTypeId: null,
                            Underwriter: null,
                            productBranding: null
                        });
                        setFormActive(false);
                    }
                    else {
                        handleBack();
                    }
                }, handleClose: function () {
                    setRestartRequest(false);
                    setAnswers(null);
                    // setProductId(null);
                    setApplicationDataId(null);
                    setQuestionLast(false);
                    setQuestionsStart(true);
                    setCurrQuestionGroup(0);
                    setNavigateHistory([]);
                    setSkippedGroups(0);
                    setQuestions(null);
                    setRemovedQuestions(null);
                    setProduct({
                        id: null,
                        productName: null,
                        productParameters: null,
                        productQuestionsSet: null,
                        createdAt: null,
                        updatedAt: null,
                        UnderwriterId: null,
                        ProductTypeId: null,
                        Underwriter: null
                    });
                    setFormActive(false);
                    resetFormState();
                } }),
            React.createElement("div", { className: "flex flex-col w-full py-4 px-6" },
                React.createElement(PolicyFormProgressBar, { steps: Object.keys(questions).length - skippedGroups, activeStep: currQuestionGroup + 1 - skippedGroups }),
                React.createElement("h1", { className: "capitalize text-2xl text-[#1F2751] font-bold mt-3 text-center" },
                    camelToWords(Object.keys(questions)[currQuestionGroup]),
                    " details"))),
        React.createElement("div", { className: "w-full flex flex-col items-start justify-start px-6 pt-8 overflow-y-auto" },
            React.createElement("div", { className: "flex flex-col gap-y-6 w-full" },
                React.createElement("div", { className: "grid grid-cols-1 gap-x-8 sm:grid-cols-2" }, ((_e = (_d = questions[Object.keys(questions)[currQuestionGroup]]) === null || _d === void 0 ? void 0 : _d.options) === null || _e === void 0 ? void 0 : _e.repeatsByValue) ? (React.createElement("div", { className: "flex flex-col gap-y-8" }, (_h = Array.from({
                    length: parseFloat(findProperty(answers, (_g = (_f = questions[Object.keys(questions)[currQuestionGroup]]) === null || _f === void 0 ? void 0 : _f.options) === null || _g === void 0 ? void 0 : _g.targetKey))
                })) === null || _h === void 0 ? void 0 : _h.map(function (i, index) {
                    var _a, _b;
                    return (React.createElement("div", null,
                        React.createElement("h1", { className: "capitalize text-xl font-semibold my-4 text-left" },
                            converter.toWordsOrdinal(index + 1),
                            ' ',
                            pluralize.singular(camelToWords(Object.keys(questions)[currQuestionGroup])),
                            ' ',
                            "details:"), (_b = (_a = questions[Object.keys(questions)[currQuestionGroup]]) === null || _a === void 0 ? void 0 : _a.questions) === null || _b === void 0 ? void 0 :
                        _b.map(function (ques) {
                            var _a, _b, _c;
                            return (React.createElement("div", { className: "w-full" },
                                React.createElement(PolicyFormInput, { questionKey: "".concat(ques.questionKey + index), label: ques.question, value: ((_a = answers === null || answers === void 0 ? void 0 : answers[Object.keys(questions)[currQuestionGroup]]) === null || _a === void 0 ? void 0 : _a[ques.questionKey + index])
                                        ? answers[Object.keys(questions)[currQuestionGroup]][ques.questionKey + index]
                                        : (ques === null || ques === void 0 ? void 0 : ques.value)
                                            ? ques.value
                                            : null, inputType: ques.inputType, required: ques.required, handleChange: function () { }, handleBlur: formik.handleBlur, handleChangeDate: function () { }, options: ques === null || ques === void 0 ? void 0 : ques.options, helper: ques.helper, placeholder: ques.placeholder, defaultChecked: answers === null || answers === void 0 ? void 0 : answers[(_b = Object.keys(questions)) === null || _b === void 0 ? void 0 : _b[currQuestionGroup]], isHidden: (_c = removedQuestions[Object.keys(questions)[currQuestionGroup]]) === null || _c === void 0 ? void 0 : _c.includes(ques.questionKey + index), min: ques.min, max: ques.max })));
                        })));
                }))) : ((_k = (_j = questions[Object.keys(questions)[currQuestionGroup]]) === null || _j === void 0 ? void 0 : _j.questions) === null || _k === void 0 ? void 0 : _k.map(function (ques) {
                    var _a, _b, _c, _d, _e;
                    return (React.createElement("div", { className: "w-full" },
                        React.createElement(PolicyFormInput, { questionKey: ques.questionKey, label: ques.question, value: (_a = formik === null || formik === void 0 ? void 0 : formik.values) === null || _a === void 0 ? void 0 : _a[ques === null || ques === void 0 ? void 0 : ques.questionKey], inputType: ques.inputType, required: ques.required, min: ques.min, max: ques.max, helper: ques.helper, handleChange: formik === null || formik === void 0 ? void 0 : formik.handleChange(ques.questionKey), handleChangeDate: function (date) {
                                var dateObj = new Date(date);
                                formik.setFieldValue(ques.questionKey, dateObj);
                            }, handleBlur: formik.handleBlur, options: ques === null || ques === void 0 ? void 0 : ques.options, placeholder: ques.placeholder, defaultChecked: answers === null || answers === void 0 ? void 0 : answers[(_b = Object.keys(questions)) === null || _b === void 0 ? void 0 : _b[currQuestionGroup]], isHidden: (_c = removedQuestions[Object.keys(questions)[currQuestionGroup]]) === null || _c === void 0 ? void 0 : _c.includes(ques.questionKey), errors: (_d = formik.errors) === null || _d === void 0 ? void 0 : _d[ques === null || ques === void 0 ? void 0 : ques.questionKey], touched: (_e = formik.touched) === null || _e === void 0 ? void 0 : _e[ques === null || ques === void 0 ? void 0 : ques.questionKey] })));
                })))))));
};
var PolicyForm = memo(function (_a) {
    var _b, _c;
    var scrollableElementRef = _a.scrollableElementRef, setRestartRequest = _a.setRestartRequest, setSelectedQuote = _a.setSelectedQuote, handleCreateKaynaHash = _a.handleCreateKaynaHash;
    var queryClient = useQueryClient();
    var _d = useErrorStore(), error = _d.error, setError = _d.setError;
    var _e = useWidgetStore(), applicationDataId = _e.applicationDataId, modalOpen = _e.modalOpen, answers = _e.answers, setModalOpen = _e.setModalOpen, setFormActive = _e.setFormActive, setAnswers = _e.setAnswers, setApplicationDataId = _e.setApplicationDataId, setCreateQuoteSubmitted = _e.setCreateQuoteSubmitted;
    var Products = useSaasPlatformStore().Products;
    var _f = useCustomerStore(), extCustomerID = _f.extCustomerID, customerId = _f.id, applicationFormData = _f.applicationFormData, extCustomer = __rest(_f, ["extCustomerID", "id", "applicationFormData"]);
    var _g = useProductStore(), productId = _g.id, productQuestionsSet = _g.productQuestionsSet, setProduct = _g.setProduct;
    var latestQuote = useLatestQuote();
    var _h = useState({}), removedQuestions = _h[0], setRemovedQuestions = _h[1];
    var _j = useState(), questions = _j[0], setQuestions = _j[1];
    var _k = useConfigStore(), style = _k.style, keys = _k.keys, setConfig = _k.setConfig;
    var primaryColor = style.primaryColor, theme = style.theme;
    var errorHandledMutation = useErrorHandledMutation().errorHandledMutation;
    var _l = useState(false), questionLast = _l[0], setQuestionLast = _l[1];
    var _m = useState(true), questionsStart = _m[0], setQuestionsStart = _m[1];
    var _o = useState(0), currQuestionGroup = _o[0], setCurrQuestionGroup = _o[1];
    var _p = useState([]), navigateHistory = _p[0], setNavigateHistory = _p[1];
    var _q = useState(0), skippedGroups = _q[0], setSkippedGroups = _q[1];
    // const [answers, setAnswers] = useState<{} | null>(null);
    // const [productId, setProductId] = useState<string | null>(null);
    // const [applicationDataId, setApplicationDataId] = useState<string | null>(
    //   null,
    // );
    var resetFormState = function () {
        setQuestionLast(false);
        setQuestionsStart(true);
        setCurrQuestionGroup(0);
        setNavigateHistory([]);
        setSkippedGroups(0);
        setAnswers(null);
        setModalOpen(false);
    };
    // const product = useQuery(['product'], () => api.product.get(productId), {
    //   enabled: !!productId,
    //   retry: 0,
    //   onError: async (err: AxiosError) => {
    //     if (
    //       err?.message?.includes('403') ||
    //       err?.code == '403' ||
    //       JSON.stringify(err) == JSON.stringify({})
    //     ) {
    //       setError({
    //         error:
    //           'Oops! 😔 It seems your token has expired. Please refresh and try again.',
    //       });
    //     } else {
    //       setError({
    //         error: 'Oops! 😞 An error occurred. Please refresh and try again.',
    //       });
    //     }
    //   },
    //   async onSuccess(res: AxiosResponse) {
    //     setError({ error: null });
    //     setProduct(res.data.data);
    //     const baseTheme = {
    //       '--theme-primary': res.data?.data?.productBranding?.primaryColor
    //         ? res.data?.data?.productBranding?.primaryColor
    //         : style.primaryColor
    //         ? style.primaryColor
    //         : 'purple',
    //       '--theme-secondary': res.data?.data?.productBranding?.secondaryColor
    //         ? res.data?.data?.productBranding?.secondaryColor
    //         : style.secondaryColor
    //         ? style.secondaryColor
    //         : 'white',
    //     };
    //     const root = document.getElementById('kayna');
    //     Object.keys(baseTheme).forEach((cssVar) => {
    //       root.style.setProperty(cssVar, baseTheme[cssVar]);
    //     });
    //     setConfig({
    //       style: {
    //         ...style,
    //         primaryColor: res.data?.data?.productBranding?.primaryColor
    //           ? res.data?.data?.productBranding?.primaryColor
    //           : style.primaryColor
    //           ? style.primaryColor
    //           : 'purple',
    //         secondaryColor: res.data?.data?.productBranding?.secondaryColor
    //           ? res.data?.data?.productBranding?.secondaryColor
    //           : style.secondaryColor
    //           ? style.secondaryColor
    //           : 'white',
    //       },
    //       keys: keys,
    //     });
    //   },
    // });
    var createQuote = useMutation(api.quote.create, {
        onSuccess: function (response) {
            var _a, _b;
            setCreateQuoteSubmitted(true);
            if (keys.widgetType == 'broker') {
                handleCreateKaynaHash();
            }
            else {
                var newQuoteId = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.quote) === null || _b === void 0 ? void 0 : _b.id;
                setSelectedQuote(newQuoteId);
                setRestartRequest(false);
                setAnswers(null);
                // setProductId(null);
                setApplicationDataId(null);
                setQuestionLast(false);
                setQuestionsStart(true);
                setCurrQuestionGroup(0);
                setNavigateHistory([]);
                setSkippedGroups(0);
                setQuestions(null);
                setRemovedQuestions(null);
                setProduct({
                    id: null,
                    productName: null,
                    productParameters: null,
                    productQuestionsSet: null,
                    createdAt: null,
                    updatedAt: null,
                    UnderwriterId: null,
                    ProductTypeId: null,
                    Underwriter: null,
                    productBranding: null
                });
                setFormActive(false);
                queryClient.invalidateQueries(['customer']);
            }
        }
    });
    var updateQuote = useMutation(api.quote.update, {
        onSuccess: function () {
            queryClient.invalidateQueries(['customer']);
        }
    });
    var createQuoteMutation = function (items, customerID, quoteExternalId, selectedProductId) {
        return errorHandledMutation(function () {
            return createQuote.mutateAsync({
                customerID: customerID,
                productId: selectedProductId ? selectedProductId : productId,
                items: items ? items : extCustomer.items,
                saasId: keys.platformId,
                externalID: extCustomerID,
                quoteExternalId: quoteExternalId
            });
        });
    };
    var updateQuoteMutation = function (items, customerID, quoteExternalId) {
        return errorHandledMutation(function () {
            return updateQuote.mutateAsync({
                quoteID: latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.id,
                customerID: customerID,
                productId: productId,
                items: items ? items : extCustomer.items,
                saasId: keys.platformId,
                externalID: extCustomerID
            });
        });
    };
    var handleConditions = function (conditions) {
        // Check if any condition is met
        var targetValue = findProperty(answers, conditions.targetKey);
        if (conditions.requiresIncl) {
            // Check if the target input value includes the specified string
            if (targetValue &&
                targetValue
                    .toLowerCase()
                    .includes(conditions.requiresIncl.toLowerCase())) {
                return true;
            }
        }
        if (conditions.requiresExact) {
            // Check if the target input value is exactly equal to the specified string
            if (targetValue &&
                targetValue.toLowerCase() === conditions.requiresExact.toLowerCase()) {
                return true;
            }
        }
        if (conditions.setsMin || conditions.setsMax) {
            // Check if the target input value is a number
            return true;
        }
        if (conditions.repeatsByValue) {
            // Check if the target input value equals the specified value
            return true;
        }
        if (conditions.hidesIfTargetVal &&
            targetValue &&
            targetValue.trim() !== '') {
            // Check if the target input has a value
            return false;
        }
        return false;
    };
    var findQuestionsWithConditions = function (indexToCheck) {
        var _a, _b;
        var questionFilter;
        var removedQuestionsLocal = [];
        var foundIndex = -1; // Initialize to -1, meaning not found yet.
        var _loop_1 = function (i) {
            if (!questions)
                return "break";
            var questionGroup = (_b = questions[Object.keys(questions)[i]]) === null || _b === void 0 ? void 0 : _b.questions;
            for (var _i = 0, questionGroup_1 = questionGroup; _i < questionGroup_1.length; _i++) {
                var item = questionGroup_1[_i];
                if (item.conditions && !handleConditions(item.conditions)) {
                    removedQuestionsLocal.push(item.questionKey);
                }
            }
            setRemovedQuestions(function (prevRemovedQuestions) {
                var _a;
                var key = Object.keys(questions)[i];
                return __assign(__assign({}, prevRemovedQuestions), (_a = {}, _a[key] = __spreadArray(__spreadArray([], ((prevRemovedQuestions === null || prevRemovedQuestions === void 0 ? void 0 : prevRemovedQuestions[key]) || []), true), removedQuestionsLocal, true), _a));
            });
            if ((removedQuestionsLocal === null || removedQuestionsLocal === void 0 ? void 0 : removedQuestionsLocal.length) !== (questionGroup === null || questionGroup === void 0 ? void 0 : questionGroup.length)) {
                // If the lengths are not equal, set the foundIndex and break the loop.
                foundIndex = i;
                return "break";
            }
            else {
                setSkippedGroups(skippedGroups + 1);
            }
            // Reset removedQuestionsLocal for the next iteration
            removedQuestionsLocal = [];
        };
        for (var i = indexToCheck; i < ((_a = Object.keys(questions)) === null || _a === void 0 ? void 0 : _a.length); i++) {
            var state_1 = _loop_1(i);
            if (state_1 === "break")
                break;
        }
        // Check if any group met the condition
        var conditionMet = foundIndex !== -1;
        return conditionMet ? foundIndex : indexToCheck; // Return the found index or null if not found.
    };
    useEffect(function () {
        if (questions || !productId)
            return;
        var findProduct = Products === null || Products === void 0 ? void 0 : Products.find(function (v) { return v.id == productId; });
        if (!findProduct) {
            setError({
                error: 'Oops! 😔 It seems product is not configured correctly. Please check your configuration.'
            });
        }
        else {
            setError({ error: null });
            setProduct(findProduct);
        }
    });
    useEffect(function () {
        if (latestQuote &&
            (latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.cerityQuoteId) &&
            (latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.quoteQuestions)) {
            setQuestions(JSON.parse("".concat(latestQuote.quoteQuestions)));
        }
        else if (productQuestionsSet && JSON.parse(productQuestionsSet)) {
            setQuestions(JSON.parse("".concat(productQuestionsSet)));
        }
    }, [productQuestionsSet, latestQuote]);
    useEffect(function () {
        if (questions && questionsStart && modalOpen) {
            var questionFilter = findQuestionsWithConditions(currQuestionGroup);
            // if (
            //   (latestQuote && latestQuote?.status !== 'rejected') ||
            //   Object.keys(questions as any)?.length == 1
            // ) {
            //   setQuestionLast(true);
            // }
            setModalOpen(true);
            // setAnswers(null);
            setNavigateHistory([0]);
            setQuestionsStart(false);
            setFormActive(true);
        }
    }, [questions, modalOpen]);
    var customer = queryClient.getQueryData(['customer']);
    var product = useMemo(function () {
        var _a, _b, _c, _d, _e, _f;
        if (!applicationDataId)
            return null;
        var productId = (_a = applicationFormData === null || applicationFormData === void 0 ? void 0 : applicationFormData[applicationDataId]) === null || _a === void 0 ? void 0 : _a.productId;
        if (!productId)
            return null;
        return (_f = (_e = (_d = (_c = (_b = customer === null || customer === void 0 ? void 0 : customer.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.SaasPlatform) === null || _d === void 0 ? void 0 : _d.Products) === null || _e === void 0 ? void 0 : _e.find) === null || _f === void 0 ? void 0 : _f.call(_e, function (p) { return p.id === productId; });
    }, [customer, applicationFormData, applicationDataId]);
    var isLimit = Boolean(((_b = product === null || product === void 0 ? void 0 : product.Underwriter) === null || _b === void 0 ? void 0 : _b.widgetFlow) === 'limit');
    if (createQuote.isLoading || updateQuote.isLoading) {
        return (React.createElement(QuoteLoading, { showAnimated: true, textHeading: isLimit ? 'Preparing your proposal form please wait' : undefined, justifyCenter: true }));
    }
    if (((_c = queryClient.getQueryState(['customer'])) === null || _c === void 0 ? void 0 : _c.status) == 'loading') {
        return React.createElement(QuoteLoading, null);
    }
    return (React.createElement("div", { className: "flex justify-center w-full text-textColor" }, questions && modalOpen && (React.createElement(FormSection, { modalOpen: modalOpen, currQuestionGroup: currQuestionGroup, questionLast: questionLast, questions: questions, skippedGroups: skippedGroups, answers: answers, removedQuestions: removedQuestions, setRemovedQuestions: setRemovedQuestions, setAnswers: setAnswers, findQuestionsWithConditions: findQuestionsWithConditions, setCurrQuestionGroup: setCurrQuestionGroup, setNavigateHistory: setNavigateHistory, navigateHistory: navigateHistory, setQuestionLast: setQuestionLast, scrollableElementRef: scrollableElementRef, createQuoteMutation: createQuoteMutation, updateQuoteMutation: updateQuoteMutation, latestQuote: latestQuote, customerId: customerId, resetFormState: resetFormState, applicationDataId: applicationDataId, setRestartRequest: setRestartRequest, 
        // setProductId={setProductId}
        setApplicationDataId: setApplicationDataId, setQuestionsStart: setQuestionsStart, setSkippedGroups: setSkippedGroups, setQuestions: setQuestions, setProduct: setProduct, setFormActive: setFormActive }))));
});
export default PolicyForm;
