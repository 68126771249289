import React, { useState } from 'react';
import useWidgetStore from '../../../../stores/widgetStore';
import QuoteRejectedIcon from '../../../icons/QuoteRejectedIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
var QuoteRejected = function (_a) {
    var _b = _a.headingText, headingText = _b === void 0 ? 'Oops! It seems that the quote has been rejected' : _b, _c = _a.contentText, contentText = _c === void 0 ? 'The information provided during the request does not match the records.' : _c, _d = _a.buttonText, buttonText = _d === void 0 ? 'Restart Request' : _d, setRestartRequest = _a.setRestartRequest, _e = _a.icon, icon = _e === void 0 ? React.createElement(QuoteRejectedIcon, null) : _e, _f = _a.buttonClasses, buttonClasses = _f === void 0 ? 'px-3 py-1.5 text-sm text-white bg-[#FD515E] font-bold border-2 border-[#FD515E] rounded-lg ' : _f, _g = _a.moreDetails, moreDetails = _g === void 0 ? null : _g;
    var _h = useState(false), showMoreDetails = _h[0], setShowMoreDetails = _h[1];
    var _j = useWidgetStore(), setModalOpen = _j.setModalOpen, setFormActive = _j.setFormActive;
    var toggleMoreDetails = function () {
        setShowMoreDetails(function (prev) { return !prev; });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex flex-col items-center justify-start px-4 md:px-0" },
            React.createElement("div", { className: "flex flex-col items-center justify-center max-w-[580px]" },
                icon,
                headingText && (React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, headingText)),
                contentText && (React.createElement("span", { className: "text-[16px] mt-3 font-semibold" }, contentText)),
                moreDetails && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "mt-6" },
                        React.createElement("button", { onClick: toggleMoreDetails, className: "flex items-center gap-2 underline" },
                            "View More Details",
                            ' ',
                            React.createElement(FontAwesomeIcon, { icon: showMoreDetails ? faChevronUp : faChevronDown }))),
                    React.createElement("div", { className: "mt-2" }, showMoreDetails ? moreDetails : null))),
                React.createElement("div", { className: "mt-6" },
                    React.createElement("button", { className: buttonClasses, onClick: function () {
                            setModalOpen(true);
                            setRestartRequest(true);
                            setFormActive(true);
                        } }, buttonText))))));
};
export default QuoteRejected;
