import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
var createArrayFromNumber = function (number) {
    return Array.from(Array(number).keys());
};
var ProgressBar = function (_a) {
    var active = _a.active, hasError = _a.hasError;
    return (React.createElement("div", { className: "w-full ".concat(hasError ? 'bg-red-400' : 'bg-primary h-1.5', " rounded-full ").concat(active ? 'opacity-100' : 'opacity-10') }, hasError && (React.createElement(FontAwesomeIcon, { icon: faExclamationCircle, size: "sm", color: '#ffffff' }))));
};
var PolicyFormProgressBar = function (_a) {
    var _b;
    var steps = _a.steps, activeStep = _a.activeStep, _c = _a.errorSteps, errorSteps = _c === void 0 ? null : _c;
    return (React.createElement("div", { className: "w-full flex flex-col items-center gap-y-3" },
        React.createElement("div", { className: "flex items-baseline text-xl  gap-x-1" },
            React.createElement("span", { className: "font-bold" }, activeStep),
            React.createElement("span", { className: "font-semibold" },
                "/ ",
                steps)),
        React.createElement("div", { className: "flex items-center justify-center gap-x-1 w-full" }, (_b = createArrayFromNumber(steps)) === null || _b === void 0 ? void 0 : _b.map(function (step, index) {
            var hasError = Boolean(errorSteps === null || errorSteps === void 0 ? void 0 : errorSteps[index]);
            return (React.createElement(ProgressBar, { active: activeStep >= index + 1, hasError: hasError }));
        }))));
};
export default PolicyFormProgressBar;
