var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState, useMemo, useEffect } from 'react';
import * as yup from 'yup';
import { capitalize } from 'lodash';
var useLimitForm = function (recordQs, latestQuote, requiredDependency) {
    var _a = useState({}), changedFields = _a[0], setChangedFieldsInternal = _a[1];
    var allSections = useMemo(function () {
        var sections = {};
        Object.entries(recordQs).map(function (_a) {
            var _b, _c, _d, _e;
            var key = _a[0], _f = _a[1], section = _f.section, schema = _f.schema;
            var childHasError = false;
            var errorFields = __spreadArray([], (((_b = sections[section]) === null || _b === void 0 ? void 0 : _b.errorFields) || []), true);
            if (schema.type === 'object') {
                childHasError = Object.keys(schema.properties || {}).some(function (childKey) {
                    var _a, _b;
                    var fullChildKey = "".concat(key, ".").concat(childKey);
                    var errExist = ((_b = (_a = latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.partnerData) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b[fullChildKey]) &&
                        Boolean(changedFields[fullChildKey]) === false;
                    if (errExist) {
                        errorFields.push(fullChildKey);
                    }
                    return errExist;
                });
            }
            var fieldHasError = (childHasError || Boolean((_d = (_c = latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.partnerData) === null || _c === void 0 ? void 0 : _c.errors) === null || _d === void 0 ? void 0 : _d[key])) &&
                Boolean(changedFields[key]) === false;
            if (fieldHasError && !childHasError) {
                errorFields.push(key);
            }
            sections[section] = {
                hasError: Boolean((_e = sections[section]) === null || _e === void 0 ? void 0 : _e.hasError) || fieldHasError || false,
                errorFields: errorFields
            };
        });
        return Object.entries(sections).map(function (_a) {
            var name = _a[0], _b = _a[1], hasError = _b.hasError, errorFields = _b.errorFields;
            return {
                name: name,
                hasError: hasError,
                errorFields: errorFields
            };
        });
    }, [recordQs, latestQuote, changedFields]);
    var _b = useState(false), disableErrors = _b[0], setDisableErrors = _b[1];
    var _c = useState(0), activeSection = _c[0], setActiveSection = _c[1];
    var _d = useState(null), scrollToField = _d[0], setScrollToField = _d[1];
    var _e = useState(), errors = _e[0], setErrors = _e[1];
    var isLastStep = activeSection === allSections.length - 1;
    var questions = useMemo(function () {
        return Object.entries(recordQs).map(function (_a) {
            var key = _a[0], config = _a[1];
            return (__assign(__assign({}, config), { key: key }));
        });
    }, [recordQs, isLastStep, activeSection, allSections]);
    useEffect(function () {
        var _a, _b;
        if (scrollToField) {
            var scrollElem = document.querySelector('#containerId > div');
            var elementTop = Number((_b = (_a = document
                .getElementById("".concat(scrollToField, "-cont"))) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.top) +
                Number(scrollElem === null || scrollElem === void 0 ? void 0 : scrollElem.scrollTop) -
                100;
            if (!isNaN(elementTop) && elementTop >= 0) {
                scrollElem === null || scrollElem === void 0 ? void 0 : scrollElem.scrollTo(0, elementTop);
                setScrollToField(null);
            }
        }
    }, [scrollToField]);
    useEffect(function () {
        if (disableErrors) {
            var section = allSections[activeSection];
            var errorField = section.errorFields[0];
            if (errorField) {
                setScrollToField(errorField);
            }
        }
    }, [activeSection, disableErrors]);
    /**
     * Generating Validation schema
     */
    var getFormSchema = function (answers) {
        var validationObject = {};
        questions.forEach(function (question) {
            var _a, _b;
            var validation;
            if (!isLastStep &&
                question.section !== ((_a = allSections === null || allSections === void 0 ? void 0 : allSections[activeSection]) === null || _a === void 0 ? void 0 : _a.name)) {
                return null;
            }
            var isBoolean = question.schema.type === 'boolean';
            var isNumber = question.schema.type === 'integer';
            var isObject = question.schema.type === 'object';
            var isArray = question.schema.type === 'array';
            var isEnum = Boolean(question.schema["enum"]);
            var isDropdown = question.schema.type === 'string' && question.choices;
            var dependencies = Object.entries(((_b = requiredDependency[question.key]) === null || _b === void 0 ? void 0 : _b.required_if) || {});
            var isDependentlyRequired = dependencies.length > 0 &&
                dependencies.every(function (_a) {
                    var key = _a[0], value = _a[1];
                    var requiredValue = value;
                    var enteredValue = answers[key];
                    return requiredValue === enteredValue;
                });
            var isRequired = question.required || isDependentlyRequired;
            if (isObject) {
                var objValidation_1 = {};
                Object.entries(question.schema.properties || {}).map(function (_a) {
                    var _b;
                    var key = _a[0], config = _a[1];
                    var innerValidation;
                    var isRequired = question.schema.required.includes(key);
                    var isNullable = (_b = config.anyOf) === null || _b === void 0 ? void 0 : _b.some(function (a) { return a["const"] === null; });
                    var isBoolean = config.type === 'boolean';
                    var isNumber = config.type === 'integer';
                    var isArray = config.type === 'array';
                    if (isNumber) {
                        innerValidation = yup.number().min(1, 'Required');
                    }
                    else if (isBoolean) {
                        innerValidation = yup.boolean();
                    }
                    else if (isArray) {
                        innerValidation = yup.array(yup.string());
                    }
                    else {
                        innerValidation = yup.string();
                    }
                    if (isRequired) {
                        var error = void 0;
                        if (isBoolean || isArray) {
                            error = 'Please select an option';
                        }
                        else if (key === 'selection') {
                            error = "".concat(capitalize(question.key.replaceAll('_', ' ')), " is required");
                        }
                        innerValidation = innerValidation.required(error);
                    }
                    if (isNullable) {
                        innerValidation = innerValidation.nullable();
                    }
                    objValidation_1[key] = innerValidation.label(capitalize(key.replaceAll('_', ' ')));
                });
                validation = yup.object().shape(objValidation_1);
            }
            else if (isBoolean) {
                validation = yup.boolean();
                if (!isRequired) {
                    validation = validation.nullable();
                }
            }
            else if (isNumber) {
                validation = yup.number().min(1, 'Required');
            }
            else if (isArray) {
                validation = yup.array(yup.string()).min(isRequired ? 1 : 0);
            }
            else {
                validation = yup.string();
            }
            if (isRequired) {
                validation = validation.required(isBoolean || isArray ? 'Please select an option' : undefined);
            }
            if ((isEnum && !isRequired) || (isDropdown && !isRequired)) {
                validation = validation.nullable();
            }
            validationObject[question.key] = validation.label(capitalize(question.key.replaceAll('_', ' ')));
        });
        return yup.object().shape(validationObject);
    };
    var setChangedFields = function (fields) {
        var newChangedFields = __assign({}, changedFields);
        fields.forEach(function (field) {
            newChangedFields[field] = true;
        });
        setChangedFieldsInternal(newChangedFields);
    };
    var extractFormValuesUnsafe = function (form) {
        var formData = new FormData(form);
        var formValues = {};
        questions.forEach(function (question) {
            var _a;
            if (!isLastStep &&
                question.section !== ((_a = allSections === null || allSections === void 0 ? void 0 : allSections[activeSection]) === null || _a === void 0 ? void 0 : _a.name)) {
                return null;
            }
            var isCustom = Boolean(question.schema.additionalProperties);
            var isBoolean = question.schema.type === 'boolean';
            var isNumber = question.schema.type === 'integer';
            var isObject = question.schema.type === 'object';
            var isArray = question.schema.type === 'array';
            var isDropdown = question.schema.type === 'string' && question.choices;
            if (isCustom) {
            }
            else if (isObject) {
                var objValues_1 = {};
                Object.entries(question.schema.properties || {}).map(function (_a) {
                    var key = _a[0], config = _a[1];
                    var inputKey = "".concat(question.key, ".").concat(key);
                    var isBoolean = config.type === 'boolean';
                    var isNumber = config.type === 'integer';
                    var isArray = config.type === 'array';
                    var isSelect = key === 'selection' && question.choices;
                    if (isBoolean) {
                        var inputValue = formData.get(inputKey);
                        objValues_1[key] = inputValue ? inputValue === 'yes' : null;
                    }
                    else if (isNumber) {
                        objValues_1[key] = Number(formData.get(inputKey));
                    }
                    else if (isArray) {
                        var values = formData.getAll(inputKey);
                        objValues_1[key] =
                            values.length > 0 ? values : ['None of the above'];
                    }
                    else if (isSelect) {
                        var inputValue = formData.get(inputKey);
                        objValues_1[key] = inputValue || null;
                    }
                    else {
                        var inputValue = formData.get(inputKey);
                        objValues_1[key] = inputValue || null;
                    }
                });
                formValues[question.key] = objValues_1;
            }
            else if (isBoolean) {
                var inputValue = formData.get(question.key);
                formValues[question.key] = inputValue ? inputValue === 'yes' : null;
            }
            else if (isNumber) {
                formValues[question.key] = Number(formData.get(question.key));
            }
            else if (isArray) {
                var values = formData.getAll(question.key);
                formValues[question.key] =
                    values.length > 0 ? values : ['None of the above'];
            }
            else if (isDropdown) {
                var inputValue = formData.get(question.key);
                formValues[question.key] = inputValue || null;
            }
            else {
                formValues[question.key] = formData.get(question.key);
            }
        });
        return formValues;
    };
    /**
     * Form value reader
     */
    var extractFormValues = function (form) {
        var _a, _b, _c, _d, _e;
        var formValues = extractFormValuesUnsafe(form);
        try {
            if ((_a = allSections === null || allSections === void 0 ? void 0 : allSections[activeSection]) === null || _a === void 0 ? void 0 : _a.hasError) {
                throw new Error();
            }
            var formSchema = getFormSchema(formValues);
            var validForm = formSchema.validateSync(formValues, {
                abortEarly: false
            });
            setErrors(null);
            return validForm;
        }
        catch (e) {
            var yupError = e;
            var scrollToField_1 = null;
            var errorObj_1 = {};
            (_c = (_b = yupError === null || yupError === void 0 ? void 0 : yupError.inner) === null || _b === void 0 ? void 0 : _b.forEach) === null || _c === void 0 ? void 0 : _c.call(_b, function (_a) {
                var path = _a.path, message = _a.message;
                if (!scrollToField_1 && path) {
                    scrollToField_1 = path;
                }
                if (path && message) {
                    errorObj_1[path] = message;
                }
            });
            if (!scrollToField_1 && ((_e = (_d = allSections === null || allSections === void 0 ? void 0 : allSections[activeSection]) === null || _d === void 0 ? void 0 : _d.errorFields) === null || _e === void 0 ? void 0 : _e[0])) {
                scrollToField_1 = allSections[activeSection].errorFields[0];
            }
            setScrollToField(scrollToField_1);
            setErrors(errorObj_1);
            return null;
        }
    };
    var handleActiveSectionChange = function (num) {
        var scrollElem = document.querySelector('#containerId > div');
        scrollElem === null || scrollElem === void 0 ? void 0 : scrollElem.scrollTo(0, 0);
        setActiveSection(num);
    };
    return {
        questions: questions,
        extractFormValuesUnsafe: extractFormValuesUnsafe,
        extractFormValues: extractFormValues,
        errors: errors,
        setErrors: setErrors,
        allSections: allSections,
        activeSection: activeSection,
        setActiveSection: handleActiveSectionChange,
        isLastStep: isLastStep,
        changedFields: changedFields,
        setChangedFields: setChangedFields,
        disableErrors: disableErrors,
        setDisableErrors: setDisableErrors
    };
};
export default useLimitForm;
