var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useRef } from 'react';
import { capitalize } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as changeCase from 'change-case';
//
import PolicyStepsHeader from '../../common/PolicyStepsHeader';
import QuoteRejected from './Screens/QuoteRejected';
import WarningIcon from '../../icons/WarningIcon';
import QuoteLoading from './QuoteLoading';
import PolicyFormProgressBar from '../../common/PolicyFormProgressBar';
import useLimitForm from '../../../utils/useLimitForm';
import api from '../../../api';
import useWidgetStore from '../../../stores/widgetStore';
import requiredDependency from '../../../utils/requiredDependency';
var containerClasses = 'flex flex-col gap-3';
var labelClasses = 'text-[22px] text-[#252846] font-bold text-left mb-4';
var miniLabelClasses = 'block text-lg text-[#252846] font-medium text-left mb-2';
var inputControlClasses = 'p-2.5 text-xl leading-tight border-2 border-[#E9EDF7] bg-[#F8FAFE] text-[#252846] placeholder-gray-500 rounded-xl focus:outline-primary w-full';
var inputControlErrorClasses = 'text-red-500 border-red-500 focus:border-red-500 bg-red-200';
var inputContainer = 'text-left';
var errorMessageClases = 'font-medium text-left text-red-500 mt-1';
var limitKeyLabelMapper = {
    naics_code_2017: 'NAICS Code 2017',
    sic_code: 'SIC Code'
};
var LimitForm = function (_a) {
    var _b;
    var latestQuote = _a.latestQuote, recordQs = _a.questions, answers = _a.answers;
    var _c = useWidgetStore(), setModalOpen = _c.setModalOpen, setSelectedQuote = _c.setSelectedQuote;
    var formRef = useRef(null);
    var _d = useState(false), submitLoading = _d[0], setSubmitLoading = _d[1];
    var _e = useLimitForm(recordQs, latestQuote, requiredDependency), questions = _e.questions, extractFormValuesUnsafe = _e.extractFormValuesUnsafe, extractFormValues = _e.extractFormValues, errors = _e.errors, setErrors = _e.setErrors, allSectionsWithErrors = _e.allSections, activeSection = _e.activeSection, setActiveSection = _e.setActiveSection, isLastStep = _e.isLastStep, changedFields = _e.changedFields, setChangedFields = _e.setChangedFields, disableErrors = _e.disableErrors, setDisableErrors = _e.setDisableErrors;
    var enteredAnswers = formRef.current
        ? extractFormValuesUnsafe(formRef.current)
        : {};
    var queryClient = useQueryClient();
    var allSections = allSectionsWithErrors.map(function (sec) { return sec.name; });
    var hasLimitFormErrors = (_b = latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.partnerData) === null || _b === void 0 ? void 0 : _b.errors;
    var submitApplication = useMutation({
        mutationKey: ['limit', 'shareable-application'],
        mutationFn: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSubmitLoading(true);
                        return [4 /*yield*/, api.quote.submitLimitApplication(payload)];
                    case 1:
                        _a.sent();
                        queryClient.setQueryData(['customer'], function (data) {
                            return __assign(__assign({}, data), { data: __assign(__assign({}, data.data), { data: __assign(__assign({}, data.data.data), { Quotes: data.data.data.Quotes.map(function (q) {
                                            var isMatching = q.id === latestQuote.id;
                                            if (isMatching) {
                                                return __assign(__assign({}, q), { partnerData: __assign(__assign({}, q.partnerData), { submitting: true }) });
                                            }
                                            else {
                                                return q;
                                            }
                                        }) }) }) });
                        });
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries(['customer']);
        },
        onError: function (error) {
            var _a, _b;
            var additionalErrors = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors;
            if (additionalErrors) {
                setErrors(additionalErrors);
            }
        }
    });
    var closeModalHandler = function () {
        setModalOpen(false);
        setSelectedQuote(null);
    };
    var formHandler = function (form) { return __awaiter(void 0, void 0, void 0, function () {
        var formValues;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (submitApplication.isLoading)
                        return [2 /*return*/];
                    formValues = extractFormValues(form);
                    if (!formValues) return [3 /*break*/, 3];
                    if (!isLastStep) return [3 /*break*/, 2];
                    return [4 /*yield*/, submitApplication.mutateAsync({
                            quoteId: latestQuote.id,
                            answers: formValues
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    setActiveSection(function (v) { return v + 1; });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var submitHandler = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            e.preventDefault();
            formHandler(e.currentTarget);
            return [2 /*return*/];
        });
    }); };
    if (hasLimitFormErrors && !disableErrors) {
        return (React.createElement("div", { className: "flex h-full items-center justify-center" },
            React.createElement(QuoteRejected, { headingText: "We need you to answer a few more questions.", contentText: "You're just a few steps away from securing your insurance policy.", buttonText: "Continue Form", setRestartRequest: setDisableErrors, icon: React.createElement(WarningIcon, null), buttonClasses: "h-12 px-10 border border-primary text-primary font-bold rounded-2xl", moreDetails: React.createElement("div", { className: "text-left" },
                    React.createElement("h3", { className: "text-xl font-semibold mb-2" }, "Change required in fields:"),
                    React.createElement("ul", { className: "list-disc flex flex-col gap-2" }, Object.keys(hasLimitFormErrors).map(function (key) {
                        var label = key
                            .split('.')
                            .map(function (item) { return capitalize(item.replaceAll('_', ' ')); })
                            .join(' > ');
                        return React.createElement("li", null, label);
                    }))) })));
    }
    if (submitLoading) {
        return React.createElement(QuoteLoading, { showAnimated: true, justifyCenter: true });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "sticky top-0 left-0 right-0 w-full bg-white -ml-4 z-10" },
            React.createElement(PolicyStepsHeader, { steps: [
                    {
                        stepText: 'Your Details',
                        isStepActive: false,
                        isStepCompleted: true
                    },
                    {
                        stepText: 'More Questions',
                        isStepActive: true,
                        isStepCompleted: false
                    },
                    {
                        stepText: 'Coverages',
                        isStepActive: false,
                        isStepCompleted: false
                    },
                    {
                        stepText: 'Your Policy',
                        isStepActive: false,
                        isStepCompleted: false
                    },
                ], nextButtonText: isLastStep ? 'Submit' : 'Next', handleNext: function () {
                    formHandler(formRef.current);
                }, isBackDisabled: activeSection === 0, backButtonText: "Back", handleClose: closeModalHandler, handleBack: function () {
                    setActiveSection(function (val) { return val - 1; });
                } })),
        React.createElement("div", { className: "p-8 w-full h-[90%] md:-ml-4" },
            React.createElement("div", { className: "mb-12" },
                React.createElement(PolicyFormProgressBar, { steps: allSections.length, activeStep: activeSection + 1, errorSteps: allSectionsWithErrors.map(function (_a) {
                        var hasError = _a.hasError;
                        return hasError;
                    }) }),
                React.createElement("h3", { className: "mt-4 capitalize text-2xl text-[#1F2751] font-bold text-center" }, changeCase.capitalCase(allSections[activeSection]))),
            React.createElement("form", { ref: formRef, onSubmit: submitHandler, className: "flex flex-col gap-8", method: "post" },
                React.createElement("div", { className: "grid gap-y-10" }, questions.map(function (question) {
                    var _a, _b, _c, _d, _e, _f;
                    var isHidden = question.section !== allSections[activeSection];
                    var isCustom = Boolean(question.schema.additionalProperties);
                    var isEnum = Boolean(question.schema["enum"]);
                    var isBoolean = question.schema.type === 'boolean';
                    var isString = (!question.schema.type || question.schema.type === 'string') &&
                        !isEnum &&
                        !question.choices;
                    var isNumber = question.schema.type === 'integer';
                    var isObject = question.schema.type === 'object';
                    var isArray = question.schema.type === 'array';
                    var isDropdown = question.schema.type === 'string' && question.choices;
                    var defaultValue = answers === null || answers === void 0 ? void 0 : answers[question.key];
                    var dependencies = Object.entries(((_a = requiredDependency[question.key]) === null || _a === void 0 ? void 0 : _a.required_if) || {});
                    var isDependentlyRequired = dependencies.length > 0 &&
                        dependencies.every(function (_a) {
                            var key = _a[0], value = _a[1];
                            var requiredValue = value;
                            var enteredValue = enteredAnswers[key];
                            return requiredValue === enteredValue;
                        });
                    var errMessage = (errors === null || errors === void 0 ? void 0 : errors[question.key]) ||
                        (changedFields[question.key]
                            ? null
                            : (_c = (_b = latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.partnerData) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c[question.key]);
                    var inputError = errMessage ? (React.createElement("p", { className: errorMessageClases }, errMessage)) : null;
                    var isRequired = question.required || isDependentlyRequired;
                    if (isCustom) {
                        return null;
                    }
                    return (React.createElement("div", { className: "".concat(containerClasses, " ").concat(isHidden ? 'hidden' : ''), id: "".concat(question.key, "-cont") },
                        React.createElement("label", { className: labelClasses, htmlFor: question.key },
                            question.label,
                            (isRequired || ((_e = (_d = question === null || question === void 0 ? void 0 : question.schema) === null || _d === void 0 ? void 0 : _d.required) === null || _e === void 0 ? void 0 : _e.length) > 0) &&
                                (!isObject ||
                                    question.kind === 'yes_no_with_explanation') ? (React.createElement("span", { className: "text-red-500" }, " *")) : null),
                        isObject ? (React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-6" }, Object.entries(question.schema.properties || {}).map(function (_a) {
                            var _b, _c, _d, _e, _f;
                            var key = _a[0], config = _a[1];
                            var inputKey = "".concat(question.key, ".").concat(key);
                            var isNullable = (_b = config.anyOf) === null || _b === void 0 ? void 0 : _b.some(function (a) { return a["const"] === null; });
                            var isRequired = !isNullable &&
                                question.schema.required.includes(key);
                            var errMessage = (errors === null || errors === void 0 ? void 0 : errors[inputKey]) ||
                                (changedFields[inputKey]
                                    ? null
                                    : (_d = (_c = latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.partnerData) === null || _c === void 0 ? void 0 : _c.errors) === null || _d === void 0 ? void 0 : _d[inputKey]);
                            var inputLabel = (React.createElement("label", { htmlFor: inputKey, className: "".concat(miniLabelClasses, " ").concat(errMessage ? 'text-red-500' : '') },
                                limitKeyLabelMapper[key] ||
                                    capitalize(key.replaceAll('_', ' ')),
                                isRequired ? (React.createElement("span", { className: "text-red-500" }, "*")) : ('')));
                            var inputError = errMessage ? (React.createElement("p", { className: errorMessageClases }, errMessage)) : null;
                            var isSelect = key === 'selection' && question.choices;
                            var isString = config.type === 'string' ||
                                ((_e = config.anyOf) === null || _e === void 0 ? void 0 : _e.some(function (a) { return a.type === 'string'; }));
                            var isBoolean = config.type === 'boolean';
                            var isNumber = config.type === 'integer';
                            var isArray = config.type === 'array';
                            var defaultValue = (_f = answers === null || answers === void 0 ? void 0 : answers[question.key]) === null || _f === void 0 ? void 0 : _f[key];
                            if (isSelect) {
                                return (React.createElement("div", null,
                                    key !== 'selection' ? inputLabel : null,
                                    React.createElement("div", { className: inputContainer, id: "".concat(inputKey, "-cont") },
                                        React.createElement("div", null,
                                            React.createElement("select", { id: inputKey, name: inputKey, className: "".concat(inputControlClasses, " ").concat(errMessage
                                                    ? inputControlErrorClasses
                                                    : ''), defaultValue: defaultValue, onChange: function () {
                                                    setChangedFields([
                                                        inputKey,
                                                        question.key,
                                                    ]);
                                                } },
                                                React.createElement("option", { className: "bg-white text-black", value: "" }, "-- No Selection --"),
                                                question.choices.map(function (choice) { return (React.createElement("option", { className: "bg-white text-black", value: choice }, choice)); })))),
                                    inputError));
                            }
                            if (isString || isNumber) {
                                return (React.createElement("div", { className: "".concat(inputContainer, " ").concat(key === 'explanation' ? 'col-span-2' : ''), id: "".concat(inputKey, "-cont") },
                                    inputLabel,
                                    key === 'explanation' ? (React.createElement("textarea", { id: inputKey, name: inputKey, className: "".concat(inputControlClasses, " resize-none"), defaultValue: defaultValue, rows: 3, onChange: function () {
                                            setChangedFields([
                                                inputKey,
                                                question.key,
                                            ]);
                                        } })) : (React.createElement("input", { id: inputKey, name: inputKey, className: "".concat(inputControlClasses, " ").concat(errMessage ? inputControlErrorClasses : ''), type: isNumber ? 'number' : 'text', defaultValue: defaultValue, onChange: function () {
                                            setChangedFields([
                                                inputKey,
                                                question.key,
                                            ]);
                                        } })),
                                    inputError));
                            }
                            if (isBoolean) {
                                return (React.createElement("div", { className: "flex flex-col gap-3" },
                                    key !== 'bool' ? inputLabel : null,
                                    React.createElement("div", { className: inputContainer, id: "".concat(inputKey, "-cont") },
                                        React.createElement("input", { id: "".concat(inputKey, "-yes"), name: inputKey, value: "yes", type: "radio", defaultChecked: defaultValue === true, className: "hidden peer", onChange: function () {
                                                setChangedFields([
                                                    inputKey,
                                                    question.key,
                                                ]);
                                            } }),
                                        React.createElement("label", { htmlFor: "".concat(inputKey, "-yes"), className: "flex items-center gap-2 group" },
                                            React.createElement("div", { className: "h-6 w-6 rounded-full border-2 border-[#919CC2] peer-checked:group-[]:border-primary peer-checked:group-[]:border-8" }),
                                            React.createElement("span", null, "Yes"))),
                                    React.createElement("div", { className: inputContainer },
                                        React.createElement("input", { id: "".concat(inputKey, "-no"), name: inputKey, value: "no", type: "radio", defaultChecked: defaultValue === false, className: "peer hidden", onChange: function () {
                                                setChangedFields([
                                                    inputKey,
                                                    question.key,
                                                ]);
                                            } }),
                                        React.createElement("label", { htmlFor: "".concat(inputKey, "-no"), className: "flex items-center gap-2 group" },
                                            React.createElement("div", { className: "h-6 w-6 rounded-full border-2 border-[#919CC2] peer-checked:group-[]:border-primary peer-checked:group-[]:border-8" }),
                                            React.createElement("span", null, "No"))),
                                    inputError));
                            }
                            if (isArray) {
                                return (React.createElement("div", { className: "flex flex-col gap-2 col-span-2" },
                                    question.choices.map(function (choice) {
                                        var _a;
                                        return (React.createElement("div", { className: "h-14 border border-[#E9EDF7] rounded-xl px-4 flex items-center justify-between", id: "".concat(inputKey, "-cont") },
                                            React.createElement("label", { htmlFor: "".concat(inputKey, "-").concat(choice), className: "text-left flex-1" }, choice),
                                            React.createElement("input", { id: "".concat(inputKey, "-").concat(choice), name: inputKey, value: choice, type: "checkbox", defaultChecked: (_a = defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.includes) === null || _a === void 0 ? void 0 : _a.call(defaultValue, choice), className: "hidden peer", onChange: function () {
                                                    setChangedFields([
                                                        inputKey,
                                                        question.key,
                                                    ]);
                                                } }),
                                            React.createElement("label", { htmlFor: "".concat(inputKey, "-").concat(choice), className: "h-6 w-6 border-2 border-[#919CC2] rounded-lg cursor-pointer peer-checked:border-primary group" },
                                                React.createElement("div", { className: "hidden peer-checked:group-[]:flex items-center justify-center h-full w-full text-primary" },
                                                    React.createElement(FontAwesomeIcon, { icon: faCheck, color: "currentColor" })))));
                                    }),
                                    inputError));
                            }
                            return null;
                        }))) : null,
                        isBoolean ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: inputContainer },
                                React.createElement("input", { id: "".concat(question.key, "-yes"), name: question.key, value: "yes", type: "radio", defaultChecked: defaultValue === true, className: "hidden peer", onChange: function () {
                                        setChangedFields([question.key]);
                                    } }),
                                React.createElement("label", { htmlFor: "".concat(question.key, "-yes"), className: "flex items-center gap-2 group" },
                                    React.createElement("div", { className: "h-6 w-6 rounded-full border-2 border-[#919CC2] peer-checked:group-[]:border-primary peer-checked:group-[]:border-8" }),
                                    React.createElement("span", null, "Yes"))),
                            React.createElement("div", { className: inputContainer },
                                React.createElement("input", { id: "".concat(question.key, "-no"), name: question.key, value: "no", type: "radio", defaultChecked: defaultValue === false, className: "hidden peer", onChange: function () {
                                        setChangedFields([question.key]);
                                    } }),
                                React.createElement("label", { htmlFor: "".concat(question.key, "-no"), className: "flex items-center gap-2 group" },
                                    React.createElement("div", { className: "h-6 w-6 rounded-full border-2 border-[#919CC2] peer-checked:group-[]:border-primary peer-checked:group-[]:border-8" }),
                                    React.createElement("span", null, "No"))),
                            inputError)) : null,
                        isString || isNumber ? (React.createElement("div", null,
                            React.createElement("input", { id: question.key, name: question.key, className: "".concat(inputControlClasses, " ").concat(errMessage ? inputControlErrorClasses : ''), type: isNumber ? 'number' : 'text', defaultValue: defaultValue, onChange: function () {
                                    setChangedFields([question.key]);
                                } }),
                            inputError)) : null,
                        isArray ? (React.createElement(React.Fragment, null,
                            question.choices.map(function (choice) {
                                var _a;
                                return (React.createElement("div", { className: inputContainer },
                                    React.createElement("input", { id: "".concat(question.key, "-").concat(choice), name: question.key, value: choice, type: "checkbox", defaultChecked: (_a = defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.includes) === null || _a === void 0 ? void 0 : _a.call(defaultValue, choice), onChange: function () {
                                            setChangedFields([question.key]);
                                        } }),
                                    React.createElement("label", { htmlFor: "".concat(question.key, "-").concat(choice), className: "text-left" }, choice)));
                            }),
                            inputError)) : null,
                        isDropdown ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: inputContainer },
                                React.createElement("div", null,
                                    React.createElement("select", { id: question.key, name: question.key, className: inputControlClasses, defaultValue: defaultValue, onChange: function () {
                                            setChangedFields([question.key]);
                                        } },
                                        React.createElement("option", { className: "bg-white text-black", value: "" }, "-- No Selection --"),
                                        question.choices.map(function (choice) { return (React.createElement("option", { className: "bg-white text-black", value: choice }, choice)); })))),
                            inputError)) : null,
                        isEnum ? (React.createElement(React.Fragment, null, (_f = question.schema["enum"]) === null || _f === void 0 ? void 0 :
                            _f.map(function (enumValue) {
                                var _a;
                                return (React.createElement("div", { className: inputContainer },
                                    React.createElement("input", { id: "".concat(question.key, "-").concat(enumValue), name: question.key, value: enumValue, type: "radio", defaultChecked: (_a = defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.includes) === null || _a === void 0 ? void 0 : _a.call(defaultValue, enumValue), className: "hidden peer", onChange: function () {
                                            setChangedFields([question.key]);
                                        } }),
                                    React.createElement("label", { htmlFor: "".concat(question.key, "-").concat(enumValue), className: "flex items-center gap-2 group" },
                                        React.createElement("div", { className: "h-6 w-6 rounded-full border-2 border-[#919CC2] peer-checked:group-[]:border-primary peer-checked:group-[]:border-8" }),
                                        React.createElement("span", null, enumValue))));
                            }),
                            inputError)) : null));
                }))))));
};
export default LimitForm;
